import React, { useEffect, useRef, useState } from "react";
import CommonTitleBar from "../CommonTitleBar";
import styles from "../user.module.css";
import close from "../../../assests/images/logo/close.png";
import plus from "../../../assests/images/logo/plus.png";
import pencil from "../../../assests/images/logo/pencil.png";
import deleteIcon from "../../../assests/images/logo/deleteIcon.png";
import { Link } from "react-router-dom";
import { list_users } from "../../../commonApi";
import { FormControl, MenuItem, Modal, Select } from "@mui/material";
import axios from "axios";
import { backend_url, backend_url1 } from "../../../config";
import { Toast } from "primereact/toast";
import { edit_user } from "../../../commonApi";
import { delete_user } from "../../../commonApi";

import cx from "classnames";
// import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const businessunit = [
  "business unit 1",
  "business unit 2",
  "business unit 3",
  "business unit 4",
  "business unit 5",
];
const assignrole = [
  "assign role 1",
  "assign role  2",
  "assign role 3",
  "assign role 4",
  "assign role 5",
];

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [userdetails, setUserDetails] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [userEditdata, setuserEditdata] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(true);
  const [businessUnit, setbusinessUnit] = useState([]);
  // const [assignRole,setassignRole]=useState([]);
  const [Usercreatedby, setUsercreatedby] = useState([]);

  // const [allRoleDropdown, setAllRoleDropdown] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const toast = useRef(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setbusinessUnit(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // const handleChange1 = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setassignRole(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  useEffect(() => {
    getuserDetails();
  }, []);

  async function getuserDetails() {
    try {
      setOpen(true);
      const userManage = await list_users();
      setOpen(false);
      console.log("userManage-----", userManage);
      if (userManage.success) {
        setUserDetails(userManage.data);
        setUsercreatedby(userManage.created_by);
        console.log("--", userManage.created_by);
      } else {
        setOpen(false);
        showError(userManage.message);
      }
    } catch (e) {
      console.log("error---", e);
      showError(e);
    }
  }

  async function deleteUser() {
    try {
      setOpen(true);
      const delUser = document.getElementsByClassName("usercheckbox");
      console.log("--", delUser);

      let ele = [];
      for (let i of delUser) {
        console.log(i);
        if (i.checked) {
          ele.push(parseInt(i.value));
          console.log("i.name-------", i, i.value);
        }
      }
      setOpen(true);

      const deleteAlluser = await delete_user({
        user_account_id: ele,
      });
      setOpen(false);
      if (deleteAlluser.success) {
        console.log("--", deleteAlluser);
        getuserDetails();
      } else {
        showError(delUser.message);
      }
    } catch (e) {
      console.log("e--", e);
      showError(e);
    }
  }
  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };


  async function saveEditdata(e) {
    try {
      e.preventDefault();
      console.log("editttttttttt");
      setOpen(true);
      const editUser = await edit_user(userEditdata);
      setOpen(false);

      if (editUser.success) {
        setEditOpen(false);
        setEditStatus(false);
        getuserDetails();
        uncheckall();
      }
    } catch {}
  }

  function uncheckall() {
    let usercheckbox = document.getElementsByClassName("usercheckbox");
    for (let i of usercheckbox) {
      i.checked = false;
    }
  }

  async function editUserDetails() {
    try {
      let usercheckbox = document.getElementsByClassName("usercheckbox");
      console.log("usercheckbox-----", usercheckbox);
      for (let i of usercheckbox) {
        if (i.checked) {
          console.log(i.value);
          let value1 = Number(i.value);
          for (let x = 0; x < userdetails.length; x++) {
            console.log(
              "hcgvjkuegf",
              i.value,
              typeof i.value,
              userdetails[x].user_account_id,
              typeof userdetails[x].user_account_id
            );

            if (value1 === userdetails[x].user_account_id) {
              console.log("matxhde--", userdetails[x]);
              setuserEditdata(userdetails[x]);

              setuserEditdata({ ...userdetails[x], user_id: value1 });
            }
          }
          setEditOpen(true);
        }
      }
    } catch (e) {
      // showError("There is an Error--", e);
    }
  }
  console.log("--", userEditdata);

  function userEditChange(e) {
    console.log(e.target.value, e.target.name);
    setuserEditdata({ ...userEditdata, [e.target.name]: e.target.value });
  }

  function userCheckBoxChange(e) {
    console.log(e);
    let count = 0;

    let usercheckbox = document.getElementsByClassName("usercheckbox");
    let flag = false;

    console.log("usercheckbox-----", usercheckbox);

    for (let i of usercheckbox) {
      if (i.checked) {
        flag = true;
        count = count + 1;
      }
    }
    console.log("count----", count);
    if (flag) {
      setDeleteStatus(false);
    } else {
      setDeleteStatus(true);
    }
    if (count === 1) {
      setEditStatus(true);
    } else {
      setEditStatus(false);
    }
  }

  console.log("editUserDetails------", editStatus);

  return (
    <div>
      <Toast ref={toast} />
      <CommonTitleBar />
      <div className={styles.inneruser}>
        <div className={styles.flex}>
          <Link to="/userManagement/usercreate">
            <div className={styles.commonbtn}>
              <img src={plus} alt="" /> <span>New User</span>
            </div>
          </Link>
          <div
            className={styles.commonbtn}
            // onClick={() => setEditOpen(true)}
            onClick={() => {
              editStatus && editUserDetails();
            }}
            style={{ opacity: editStatus ? 1 : 0.5 }}
          >
            <img src={pencil} alt="" /> <span> Edit</span>
          </div>

          <div
            className={styles.commonbtn}
            style={{ opacity: deleteStatus ? 0.5 : 1 }}
            onClick={() => deleteUser()}
          >
            <img src={deleteIcon} alt="" /> <span>Delete</span>
          </div>
        </div>
      </div>
      <div className={styles.usertable}>
        <table>
          <thead>
            <tr>
              <th className={styles.padlef40}>
                <input
                  type="checkbox"
                  // value={item.user_account_id}
                  // onChange={userCheckBoxChange}
                  className={cx(
                    "usercheckboxparent",
                    styles.usercheckbox,
                    styles.mr32
                  )}
                ></input>
                <span className={styles.ml8}> FULL NAME</span>
              </th>
              <th>USER EMAIL</th>
              <th>BUSINESS UNIT</th>
              <th>USER ROLE</th>
              {/* <th>DEPARTMENT</th> */}

              <th>CREATED BY</th>
              <th>CREATED AT</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {userdetails?.map((item) => {
              // console.log("--", item);
              return (
                <tr>
                  <td className={styles.hovername_d_flex}>
                    <input
                      type="checkbox"
                      value={item.user_account_id}
                      onChange={userCheckBoxChange}
                      className={cx("usercheckbox", styles.usercheckbox)}
                    ></input>
                    <td>{item.user_firstname + " " + item.user_last_name}</td>
                  </td>

                  <td>{item.email} </td>
                  <td>{item.business_unit} </td>
                  <td>{item.assign_role} </td>
                  {/* <td>{item.department} </td> */}
                  <td> {Usercreatedby}</td>

                  <td>{item.record_created_date}</td>
                  <td classname={styles.green1}>
                    {item.is_active === "1" ? "Active" : "Pending"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex-center"
      >
        <div className={styles.modalshowforgot}>
          <img
            src={close}
            onClick={() => setEditOpen(false)}
            className={styles.closex}
            alt=""
          />

          <div className={styles.modal_con}>
            <h4 className={styles.green_edit}>Edit User</h4>
            <form onSubmit={saveEditdata}>
              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    First Name
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="text"
                    value={userEditdata?.user_firstname}
                    className={styles.inputcommontextforgot}
                    name="user_firstname"
                    id=""
                    onChange={userEditChange}
                  />
                </div>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    Last Name
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="text"
                    value={userEditdata?.user_last_name}
                    className={styles.inputcommontextforgot}
                    name="user_last_name"
                    id=""
                    onChange={userEditChange}
                  />
                </div>
              </div>

              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    Email ID
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="email"
                    value={userEditdata?.email}
                    className={styles.inputcommontextforgot}
                    name="email"
                    id=""
                    onChange={userEditChange}
                  />
                </div>
                {/* <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    Assign User Role
                  </label>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userEditdata?.assign_role}
                      label="Select your Project"
                      onChange={userEditChange}
                      className={styles.dropdwon_user}
                      name="assign_role"
                      required
                    ></Select>
                  </FormControl>
                </div> */}
              </div>

              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label className={styles.labelforgot}>Business unit</label>

                  <FormControl>
                    {/* style={{ marginTop: "8px" }} */}
                    <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={businessUnit}
                      onChange={handleChange}
                      input={<OutlinedInput label="" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      className={styles.business1}
                    >
                      {businessunit.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={businessUnit.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.each_for_box}>
                  <label className={styles.labelforgot}>Assign User Role</label>

                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userEditdata?.assign_role}
                      label="Select your Project"
                      onChange={userEditChange}
                      className={styles.business1}
                      // className={styles.dropdwon_user}
                      name="assign_role"
                      required
                    >
                      <MenuItem>Role 1</MenuItem>
                      <MenuItem>Role 2</MenuItem>
                      <MenuItem>Role 3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className={styles.modaleditbtn}>
                <button className={styles.commonbtn} type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserManagement;
