import React, { useState, useEffect } from "react";
import styles from "../ContentGeneration/blogposter.module.css";
import { GetRoles, PostRoles } from "../../commonApi";

const Keypoints = ({ title, outlines, onDataChange }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [targetingAudience, setTargetingAudience] = useState("");
  const [wordsRange, setWordsRange] = useState({ min: "", max: "" });
  const [callToActions, setCallToActions] = useState("");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    onDataChange({
      blog_description: blogDescription,
      targeting_audience: targetingAudience,
      role_description: roleDescription,
      words_range: `${wordsRange.min}-${wordsRange.max}`,
      call_to_actions: callToActions,
    });
  }, [
    blogDescription,
    targetingAudience,
    roleDescription,
    wordsRange,
    callToActions,
  ]);
  const handleSubmit = async () => {
    // Prepare the data object to send to the backend

    const data = {
      roleName: roleName,
      description: roleDescription,
    };

    // Call the POST API to add a new role
    const response = await PostRoles(data);
    if (response.success) {
      console.log("Role added successfully");
      // Fetch updated roles to reflect in the dropdown
      fetchRoles();
    } else {
      console.error("Failed to add role:", response.message);
    }

    // Close the popup
    handleClosePopup();
  };

  // Function to fetch roles
  const fetchRoles = async () => {
    const response = await GetRoles();
    if (response.success) {
      setRoles(response.data); // Update your roles state
    } else {
      console.error("Failed to fetch roles:", response.message);
    }
  };

  useEffect(() => {
    fetchRoles(); // Initial fetch on component mount
  }, []);

  const handleSelectChange = (event) => {
    if (event.target.value === "add") {
      setShowPopup(true);
      setRoleDescription(""); // Clear previous description if "Add Role..." is selected
    } else {
      const selectedRole = roles.find(
        (role) => role.RoleUniqueId === event.target.value
      );
      if (selectedRole && selectedRole.Description.Valid) {
        setRoleDescription(selectedRole.Description.String);
        setShowPopup(false); // Ensure popup is not shown if a valid role is selected
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setRoleName("");
    setRoleDescription("");
  };

  return (
    <div>
      <div className={styles.outlinertitle}>
        <h5>Title</h5>
        <h5>{title}</h5>
        <h5>Outlines</h5>
        <div className={styles.Outliners}>
          <div className={styles.outline}>
            {outlines.length > 0 ? (
              outlines?.map((outline, index) => <h6 key={index}>{outline}</h6>)
            ) : (
              <p>No outlines available.</p>
            )}
          </div>
        </div>
        <h5>Description</h5>
        <div className={styles.instructions}>
          <textarea
            className={styles.text}
            value={blogDescription}
            onChange={(e) => setBlogDescription(e.target.value)}
            placeholder="Enter blog description"
          />
        </div>

        <h5>Targeting Audience</h5>
        <input
          className={styles.inptarget}
          type="text"
          placeholder="To whom you are targeting"
          value={targetingAudience}
          onChange={(e) => setTargetingAudience(e.target.value)}
        />

        <div className={styles.parent}>
          <div className={styles.actor}>
            <h5>Actor Role</h5>
            <select
              className={styles.dropdown}
              value={roleDescription}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Select Actor Role
              </option>
              {roles?.map((role) => (
                <option key={role.RoleUniqueId} value={role.RoleUniqueId}>
                  {role.RoleName}
                </option>
              ))}
              <option value="add">Add Role...</option>
            </select>
          </div>
          <div className={styles.word}>
            <h5>Words Range</h5>
            <div className={styles.rangeInputContainer}>
              <input
                type="text"
                className={styles.rangeInput}
                placeholder="Min"
                value={wordsRange.min}
                onChange={(e) =>
                  setWordsRange({ ...wordsRange, min: e.target.value })
                }
              />
              <input
                type="text"
                className={styles.rangeInput}
                placeholder="Max"
                value={wordsRange.max}
                onChange={(e) =>
                  setWordsRange({ ...wordsRange, max: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <h5>Call To Actions</h5>
        <div className={styles.instructions}>
          <textarea
            className={styles.text}
            value={callToActions}
            onChange={(e) => setCallToActions(e.target.value)}
            placeholder="Enter call to actions"
          />
        </div>
      </div>

      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <h5>Add Role</h5>
            <div className={styles.role}>
              <h6>Actor Role Name</h6>
              <input
                type="text"
                className={styles.inputField}
                placeholder="Actor Role Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
              <h6>Description</h6>
              <textarea
                className={styles.textareaField}
                placeholder="Description"
                value={roleDescription}
                onChange={(e) => setRoleDescription(e.target.value)}
              ></textarea>
              <div className={styles.division}>
                <button className={styles.commonbtn2} onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className={styles.cancelbtn2}
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Keypoints;
