import React, { useState, useRef, useEffect } from "react";
import styles from "../Optilogistics/popupforun.module.css";

import Excel from "../../../assests/images/appstore/Excel.png";
import Json from "../../../assests/images/appstore/Json.png";
import { PostUplaodExcelApi } from "../../../commonApi";
import { Toast } from "primereact/toast";
import Charging from "../../../Charging";

const Popforrun = ({ closeModel, onRunDetailsUpdate }) => {
  const [modelName, setModelName] = useState("");
  let [rundetails, setRundetails] = useState(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const toast = useRef(null);
  const [open, setOpen] = useState(false);

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is not null
      setFile(file);
      setFileName(file.name);
      setUploadStatus("File selected: " + file.name);

      // Safely set the file name because we know file is not null
      // onFileName(file.name); // Send the file name to the parent component
    } else {
      // Handle the case where no file is selected
      setFileName("");
      // Reset the file name if no file is chosen
      setUploadStatus("No file selected");
      // onFileName(""); // Optionally notify the parent that no file is selected
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file || !modelName) {
      alert("Please provide a run name and select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("session_name", modelName);

    try {
      setOpen(true);
      const uploadResponse = await PostUplaodExcelApi(formData);
      setOpen(false);

      console.log("----------------uploadResponse.success)", uploadResponse);
      if (uploadResponse.success) {
        setUploadStatus("File uploaded successfully: " + fileName);
        setRundetails(uploadResponse);
        onRunDetailsUpdate(uploadResponse);

        closeModel();
      } else {
        setUploadStatus("Failed to upload file. Please try again.");
        toast.current.show({
          severity: "error",
          summary: "Upload Failed",
          detail:
            uploadResponse.message ||
            "Failed to upload file. Please try again.",
          life: 5000,
        });
      }

      console.log("rundetails:", rundetails);
    } catch (error) {
      console.error("Error in PostUploadExcelApi:", error);
      alert("Failed to upload file. Please try again.");
      setUploadStatus("Failed to upload file. Please try again.");
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.modal}>
        <div className={styles.modelBox}>
          <div className="justifyEnd">
            <img className="cursor" onClick={closeModel} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label className={styles.activeLabel}>Run Name</label>
            </div>

            <input
              className={styles.input}
              type="text"
              required
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />

            <div className={styles.Box}>
              <div className={styles.Boxleft}>
                <label className={styles.inside}>
                  <img src={Excel} alt="Upload Excel" />
                  <input
                    type="file"
                    accept=".xlsx,.xls"
                    style={{ display: "none" }} // Hide the input element
                    onChange={handleFileChange}
                  />
                  <h6>Upload Excel file</h6>
                </label>
              </div>
              <h6>(or)</h6>
              <div className={styles.Boxleft}>
                <label className={styles.inside}>
                  <img src={Json} alt="Upload JSON" />
                  <input
                    type="file"
                    accept=".json"
                    style={{ display: "none" }} // Hide the input element
                    onChange={handleFileChange}
                  />
                  <h6>Upload JSON file</h6>
                </label>
              </div>
            </div>

            <div className={styles.modelButton}>
              <button type="submit" className={styles.access}>
                Submit
              </button>
              <button
                type="button"
                onClick={closeModel}
                className={styles.cancel}
              >
                Cancel
              </button>
            </div>
          </form>
          {uploadStatus && (
            <div className={styles.uploadStatus}>{uploadStatus}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Popforrun;
