import React, { Suspense, lazy, useState } from "react";
import Loading from "../components/loading";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Layout from "../Layout";
import DragAndDrop from "../components/dragAndDrop";
import SameLayout from "../sameLayout";

import UserManagement from "../screens/management/usermanagement";
import BusinessManagement from "../screens/management/businessmanagement/businessmangement";
import RoleManagement from "../screens/management/rolemanagement/rolemanagement";
import UserCreate, { Usercreate } from "../screens/management/usercreate";
import rolecreate, { Rolecreate } from "../screens/management/rolecreate";
import profile, { Profile } from "../screens/management/profile";
import { useSelector } from "react-redux";
import Charging from "../Charging";
import { element } from "prop-types";
import TestAutomation from "../screens/appstore/Testautomation/TestAutomation";
import Projectsoverview from "../../src/screens/datavizard/projects/Projectsoverview";
import CreateQuestion from "../screens/datavizard/projects/CreateQuestion";
import ProjectChart from "../screens/datavizard/projects/ProjectChart";
import ChartAndTables from "../screens/datavizard/projects/ChartAndTables";
import Routeoptimizer from "../screens/appstore/Routeoptimizer/Routeoptimizer";
import Imagechat from "../screens/appstore/ImageChat/Imagechat";
import SpeechToText from "../screens/appstore/SpeechToText/SpeechToText";
import Optilogistics from "../screens/appstore/Optilogistics/Optilogistics";
import Blogposter from "../screens/ContentGeneration/Blogposter";
import Dashboard from "../screens/ContentGeneration/Dashboard";
import ProjectCRD from "../screens/ContentGeneration/ProjectCard";
import Imageediting from "../screens/ContentGeneration/imageediting";
import Poster from "../screens/ContentGeneration/poster";
import TestProjects from "../screens/appstore/AutomationTest/TestProjects";
import ProjectDetails from "../screens/appstore/AutomationTest/ProjectDetails";
const Login = lazy(() => import("../screens/login/Login"));
const Register = lazy(() => import("../screens/register/Register"));
const RegisterSuccess = lazy(() =>
  import("../screens/register/RegisterSuccess")
);
const NoramlChat = lazy(() => import("../screens/normalChat"));
const Plugins = lazy(() => import("../screens/plugins"));
const AppDesign = lazy(() => import("../screens/appDesign"));
const DatabaseChat = lazy(() => import("../screens/databaseChat"));
const Changepassword = lazy(() =>
  import("../screens/changepassword/Changepassword")
);
const Forgotpassword = lazy(() =>
  import("../screens/forgotpassword/Forgotpassword")
);
const Resendlink = lazy(() => import("../screens/resendlink/Resendlink"));
const ActivateAccount = lazy(() =>
  import("../screens/register/ActivateAccount")
);
const AccountActivated = lazy(() =>
  import("../screens/register/AccountActivated")
);
const CheckInMmail = lazy(() => import("../screens/register/CheckInMail"));
const Finetune = lazy(() => import("../screens/finetuning/Finetuning"));
const Modelfinetunechat = lazy(() =>
  import("../screens/finetuning/Modelfinetune")
);
const Modeloptimization = lazy(() =>
  import("../screens/modelopt/modeloptimization")
);
const Modeloptimizationmetrics = lazy(() =>
  import("../screens/modelopt/modeloptmetrics")
);
const Hubplus = lazy(() => import("../screens/hubplus/hubplus"));
const FlowDrag = lazy(() => import("../components/react_flow/FlowDrag"));

const DatabaseMani = lazy(() => import("../screens/database/Databasing"));
const Database = lazy(() => import("../screens/database/DataBas"));
const Graphdatabase = lazy(() => import("../GraphDatabase/Graphdatabase"));
const Appstore = lazy(() => import("../screens/appstore/Appstore"));
const Teraform = lazy(() => import("../screens/appstore/teraform/Teraform"));
const Datadive = lazy(() => import("../screens/database/Datadive"));
const DragAndDropDataSource = lazy(() =>
  import("../screens/database/Flow/DragAndDropDataSource")
);
const TextToImage = lazy(() => import("../screens/textToImage/textToImage"));
const Monitoring = lazy(() => import("../screens/monitoring/monitoring"));
const TeraformProjects = lazy(() =>
  import("../screens/appstore/teraform/teraformProjects")
);
const ProjectDatavizard = lazy(() =>
  import("../screens/datavizard/projects/ProjectDatavizard")
);
const DashboardDatavizard = lazy(() =>
  import("../screens/datavizard/dashboard/DashboardDatavizard")
);
const StepsDataSource = lazy(() =>
  import("../screens/datavizard/dashboard/StepsDataSource")
);
const PlsqlProjects = lazy(() =>
  import("../screens/appstore/plsql/plsqlprojects")
);
const JavacodeGen = lazy(() => import("../screens/appstore/plsql/javacodegen"));
const AiMed = lazy(() => import("../../src/screens/appstore/AIMed/aimed"));
// const teraformProjects = lazy(() => import("../screens/appstore/teraform/"))

const RoutingGenai = () => {
  const loading = useSelector((store) => store.loadingErrorStore.loading);
  console.log(`loading--${loading}`);

  return (
    <>
      <Charging open={loading} />
      <Suspense fallback={<Loading />}>
        <Router>
          <Routing />
        </Router>
      </Suspense>
    </>
  );
};

const Routing = () => {
  const [c1, setC1] = useState(0);

  function counting(data) {
    setC1(data);
  }

  let MainRoutes = {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <Login /> },
      { path: "/register", element: <Register /> },
      { path: "/registerSuccess", element: <RegisterSuccess /> },
      { path: "/dragAndDrop", element: <DragAndDrop /> },
      { path: "/login/change_password/:userId", element: <Changepassword /> },
      { path: "/forgotpassword", element: <Forgotpassword /> },
      { path: "/resendlink", element: <Resendlink /> },
      { path: "/login/activateuser/:userId", element: <ActivateAccount /> },
      { path: "/activated", element: <AccountActivated /> },
      { path: "/checkInMail", element: <CheckInMmail /> },
    ],
  };

  let sameRoutes = {
    path: "/",
    element: <SameLayout counting={counting} />,
    children: [
      { path: "/normalchat", element: <NoramlChat /> },
      { path: "/texttoimage", element: <TextToImage /> },
      { path: "/database123", element: <Database /> },
      { path: "/database", element: <Datadive /> },
      { path: "/databaseMani", element: <DatabaseMani /> },
      { path: "/apphub", element: <Plugins change={c1} /> },
      { path: "/database/databaseChat", element: <DatabaseChat /> },
      { path: "/modelfinetune", element: <Finetune /> },
      { path: "/modelfinetune/:jobName", element: <Modelfinetunechat /> },
      { path: "/modelopt", element: <Modeloptimization /> },
      { path: "/modelopt/:jobName", element: <Modeloptimizationmetrics /> },
      { path: "/appDesign", element: <AppDesign change={c1} /> },
      { path: "/hubplus", element: <Hubplus /> },
      { path: "/CreateProject", element: <Dashboard /> },
      {
        path: "/CreateTemplate/:projectId", // Add :projectId as a dynamic parameter
        element: <ProjectCRD />, // Render ProjectCRD component
      },
      { path: "/Imageediting", element: <Imageediting /> },

      { path: "/Blogposter/:templateId", element: <Blogposter /> },
      { path: "/Blogposter/poster/:templateId", element: <Poster /> },

      { path: "/userManagement", element: <UserManagement /> },
      { path: "/roleManagement", element: <RoleManagement /> },
      { path: "/userManagement/usercreate", element: <Usercreate /> },
      { path: "/roleManagement/rolecreate", element: <Rolecreate /> },
      { path: "/profile", element: <Profile /> },
      { path: "/database/flow", element: <FlowDrag /> },
      {
        path: "/database/dragAndDropDataSource/:ragName",
        element: <DragAndDropDataSource />,
      },
      { path: "/appstore", element: <Appstore /> },
      { path: "/teraformProjects", element: <TeraformProjects /> },
      {
        path: "/appstore/teraform/:project_id/:projectname",
        element: <Teraform />,
      },
      { path: "/monitoring", element: <Monitoring /> },
      { path: "/appstore/TestAutomation", element: <TestAutomation /> },
      { path: "/appstore/TestAutomation2", element: <TestProjects /> },
      {
        path: "/appstore/TestAutomation2/projectdetails/:projectId",
        element: <ProjectDetails />,
      },

      { path: "/plsqlProjects", element: <PlsqlProjects /> },
      { path: "/javacodegen/:plsql_id/:plsql_title", element: <JavacodeGen /> },
      { path: "/appstore/datavizard/projects", element: <ProjectDatavizard /> },
      { path: "/appstore/graphdatabase", element: <Graphdatabase /> },
      { path: "/appstore/optilogistics", element: <Optilogistics /> },
      { path: "/appstore/imagechat", element: <Imagechat /> },
      { path: "/appstore/speechtotext", element: <SpeechToText /> },

      {
        path: "/appstore/datavizard/datasource",
        element: <DashboardDatavizard />,
      },
      {
        path: "/appstore/datavizard/datasource/:datasourename",
        element: <StepsDataSource />,
      },
      {
        path: "/appstore/datavizard/projects/projectsoverview/:projectName",
        element: <Projectsoverview />,
      },
      {
        path: "/appstore/datavizard/projects/projectquestion/:projectName/:datasource_id",
        element: <CreateQuestion />,
      },
      {
        path: "/appstore/datavizard/projects/projectchart",
        element: <ProjectChart />,
      },
      {
        path: "/appstore/datavizard/projects/chartAndTables/:projectName/:datasource_id",
        element: <ChartAndTables />,
      },
      {
        path: "/appstore/ai_med_code",
        element: <AiMed />,
      },
    ],
  };

  return useRoutes([MainRoutes, sameRoutes]);
};

export default RoutingGenai;
