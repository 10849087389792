import React, { createContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import axios from "axios"; // Ensure axios is imported
import styles from "../SpeechToText/Speechsidebar.module.css";
import { Toast } from "primereact/toast";
import Charging from "../../../Charging";
import play from "../../../assests/images/appstore/play.png";
import del from "../../../assests/images/appstore/del.svg";
import EachAudio from "./EachAudio.js";
import {
  CreateSession,
  getDocQuery,
  GetSessionsAudio,
  GetSummary,
  Gettranscription,
  SpeechDeleteApi,
} from "../../../commonApi.js";

export const AudioContext = createContext();

const Speechsidebar = ({
  setShowAudioOptions,
  reload,
  setReload,
  setsessionIdurl,
  settranscriptionPage,
  setSummary,
  setsummaryVisible,
  setTranscriptionVisible,
  setActiveState,
  onSessionChange,
}) => {
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [audioModel, setAudioModel] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [initialFullData, setInitialFullData] = useState([]);
  const [optionsIndex, setOptionsIndex] = useState(-1);
  const [currentSession, setCurrentSession] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (currentSessionId) {
      fetchTranscription(currentSessionId);
      fetchSummary(currentSessionId);
    }
  }, [currentSessionId]);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default button behavior
    if (fullData.length === 0) {
      // If there are no sessions, show a toast message and return early
      // toast.current.show({
      //   severity: "error",
      //   summary: "Deletion Error",
      //   detail: "No session is there to delete",
      //   life: 3000, // Duration the toast will stay visible
      // });
      return;
    }
    if (!isDeleting && fullData.length > 0) {
      handleSessionDelete();
      setIsDeleting(true);
      setTimeout(() => {
        setIsDeleting(false);
      }, 3200); // Remove 'delete' class after 3200ms
    }
  };

  // useEffect(() => {
  //   if (currentSessionId) {
  //     fetchDocquery(currentSessionId);
  //   }
  // }, [currentSessionId]);

  useEffect(() => {
    if (currentSessionId) {
      onSessionChange(currentSessionId); // Call the callback when currentSessionId changes
    }
  }, [currentSessionId, onSessionChange]);

  const contextValue = {
    currentSession: currentSession,

    optionsIndex: optionsIndex,
    updateOptionsIndex: setOptionsIndex,
  };
  console.log("-------------------->currensession", currentSession);
  const showError = (err) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: err,
    });
  };

  const fetchTranscription = async (session_id) => {
    try {
      const transcription = await Gettranscription(session_id);
      console.log("----------------------->transcription", transcription);
      if (transcription.success) {
        settranscriptionPage(transcription.data[0].content);

        console.log("-----------------------", transcription.data[0].content);
        setTranscriptionVisible(true);
        // setActiveState("Transcription");

        // toast.current.show({
        //   severity: "success",
        //   summary: "Transcription Retrieved",
        //   detail: "Successfully retrieved transcription.",
        // });
      } else {
        showError(transcription.message);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        // detail: "Error fetching transcription: " + error.message,
      });
    }
  };

  const fetchSummary = async (session_id) => {
    try {
      const summary = await GetSummary(session_id);
      console.log("----------------------->summary", summary.data.summery);
      if (summary.success) {
        setSummary(summary.data.summery);

        console.log("-----------------------", summary.data);
        // setsummaryVisible(true);
        // setActiveState("Summary");

        // toast.current.show({
        //   severity: "success",
        //   summary: "Transcription Retrieved",
        //   detail: "Successfully retrieved transcription.",
        // });
      } else {
        showError(summary.message);
      }
    } catch (error) {
      // showError(error.message);
    }
  };

  useEffect(() => {
    resetContentDisplay();
  }, [fullData]);

  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    // Set the first session as the default session if `fullData` is populated
    if (fullData.length > 0) {
      setCurrentSession(fullData[0]?.audio_session);
      setCurrentSessionId(fullData[0]?.session_id);
      setsessionIdurl(fullData[0]?.session_id);
      setOptionsIndex(0); // Set the default selected index to 0
    }
  }, [fullData]);

  if (reload) {
    fetchSessions();
    setReload(false);
  }

  async function fetchSessions() {
    try {
      const response = await GetSessionsAudio();
      console.log("API Response:", response);
      if (response.success && response.data) {
        const formattedData = response.data.map((session) => ({
          audio_session: session.session_name,
          session_id: session.session_id,
        }));
        setFullData(formattedData);
        setInitialFullData(formattedData);
        setCurrentSession(formattedData[0]?.audio_session);
        setCurrentSessionId(formattedData[0]?.session_id);
        setsessionIdurl(formattedData[0]?.session_id);
        console.log(
          "-------------------------->currentSessionId",
          currentSessionId
        );
      } else {
        showError(response.message);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
      showError(error.message);
    }
  }

  // const handleSessionDelete = async () => {
  //   if (optionsIndex === -1) {
  //     return;
  //   }
  //   const sessionId = fullData[optionsIndex].session_id;
  //   try {
  //     const data = { sessionId };
  //     const response = await SpeechDeleteApi({ data });
  //     if (response.success) {
  //       toast.current.show({
  //         severity: "success",
  //         summary: "Delete Success",
  //         detail: "Session successfully deleted",
  //       });
  //       // Remove the deleted session from the fullData state
  //       const updatedData = fullData.filter(
  //         (item) => item.session_id !== sessionId
  //       );
  //       setFullData(updatedData);
  //       setOptionsIndex(-1); // Reset the selected index
  //     } else {
  //       showError(response.message);
  //     }
  //   } catch (error) {
  //     showError(error.response);
  //   }
  // };

  const handleSessionDelete = async () => {
    if (optionsIndex === -1) {
      return;
    }
    const sessionId = fullData[optionsIndex].session_id;
    try {
      const data = { sessionId };
      const response = await SpeechDeleteApi({ data });
      if (response.success) {
        const updatedData = fullData.filter(
          (item) => item.session_id !== sessionId
        );
        setFullData(updatedData);
        setOptionsIndex(-1); // Reset the selected index

        // Automatically select the first available session if it exists
        if (updatedData.length > 0) {
          setCurrentSession(updatedData[0].audio_session);
          setCurrentSessionId(updatedData[0].session_id);
          setsessionIdurl(updatedData[0].session_id);
          fetchTranscription(updatedData[0].session_id);
          fetchSummary(updatedData[0].session_id);
        } else {
          // No sessions left, reset all related data
          setCurrentSession(null);
          setCurrentSessionId(null);
          setsessionIdurl(null);
          setTranscriptionVisible(false);
          setsummaryVisible(false);
          settranscriptionPage("");
          setSummary("");
        }

        resetContentDisplay();
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error.toString());
    }
  };

  const resetContentDisplay = () => {
    if (fullData.length === 0) {
      setTranscriptionVisible(false);
      setsummaryVisible(false);
      settranscriptionPage("");
      setSummary("");
      setShowAudioOptions(true);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.full}>
        <div className={styles.search}>
          <div
            onClick={() => setShowAudioOptions(true)}
            className={cx("commonbtn")}
          >
            <i className="fa fa-plus"></i>
            <span>New Audio</span>
          </div>
          <div className={styles.comnbtn}>
            <button className={styles.eachbutto1}>
              <img src={play} alt="Play" />
              Play
            </button>
            {/* <button onClick={handleSessionDelete} className={styles.eachbutto}>
              <img src={del} alt="Delete" />
              Delete
            </button> */}

            <button
              className={`${styles.button} ${isDeleting ? styles.delete : ""}`}
              onClick={handleClick}
            >
              <div className={styles.trash}>
                <div className={styles.top}>
                  <div className={styles.paper}></div>
                </div>
                <div className={styles.box}></div>
                <div className={styles.check}>
                  <svg viewBox="0 0 8 6">
                    <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                  </svg>
                </div>
              </div>
              <span>Delete</span>
            </button>
          </div>
        </div>

        <AudioContext.Provider value={contextValue}>
          {fullData.map((ele, idx) => (
            <EachAudio
              key={ele.session_id}
              setOptionIndex={setOptionsIndex}
              index={idx}
              getsession={fetchSessions}
              deleteOne={handleSessionDelete}
              optionsShow={idx === optionsIndex}
              changeSession={(sessionName) => setCurrentSession(sessionName)}
              fetchTranscription={fetchTranscription}
              fetchSummary={fetchSummary}
              data={ele}
              setCurrentSessionId={setCurrentSessionId}
              optionsIndex={optionsIndex}
              setsessionIdurl={setsessionIdurl}
              setShowAudioOptions={setShowAudioOptions}
            />
          ))}
        </AudioContext.Provider>
      </div>
    </>
  );
};

export default Speechsidebar;
