import React from "react";
import styles from "../management/profile.module.css";
import pencil from "../../assests/images/logo/pencil.png";

export const Profile = () => {
  return (
    <div className={styles.middlepart}>
      <div className={styles.fixed}>
        <h4 className={styles.page_tittle}>PROFILE SETTINGS</h4>
        <div className={styles.allcontent}>
          <div className={styles.profile1}>
            <div className={styles.insidepro1}>
              <input
                type="file"
                // onChange={handleFileChange}
                className={styles.fileselect}
              />
              <div className={styles.userphoto}>K</div>
            </div>
            <h4 className={styles.name}>Katherine Isabella</h4>
            <h4 className={styles.role}>saleh14688@mcenb.com</h4>
          </div>
          <div className={styles.width100}>
            <form>
              <div className={styles.width80inp}>
                <div className={styles.d_flex}>
                  <div className={styles.eachboxinputprofile}>
                    <label>First Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                  <div className={styles.eachboxinputprofile}>
                    <label>Last Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                </div>
                <div className={styles.d_flex}>
                  <div className={styles.eachboxinputprofile}>
                    <label>User Role</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                  <div className={styles.eachboxinputprofile}>
                    <label>Mobile Number</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                </div>
                <div className={styles.eachboxinputprofile}>
                  <label>Email Id</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className={styles.firstname}
                    name="user_first_name"
                  />
                </div>
                <div className={styles.d_flex}>
                  <div className={styles.eachboxinputprofile}>
                    <label>Department</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                  <div className={styles.eachboxinputprofile}>
                    <label>Business Unit</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className={styles.firstname}
                      name="user_first_name"
                    />
                  </div>
                </div>
                <div>
                  <h4 className={styles.change}> Change password?</h4>
                </div>
                <div className={styles.fle}>
                  <button className={styles.commonbtn}>Save</button>
                </div>
              </div>
            </form>
          </div>
          <div className={styles.rightwid10}>
            <div className={styles.editbtn}>
              <img src={pencil} alt="" /> <span>Edit </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
