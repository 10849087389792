import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

const PlotGraph = ({ data, layout }) => {

    const config = {
        displayModeBar: true, // Set to false to hide the default toolbar
    };

    return (
        <Plot
            style={{ width: "100%", height: "auto", margin: "4px" }}
            data={data}
            layout={layout}
            config={config}
            className="flexCenter"
        />
    );
};

export default PlotGraph;
