import React, { useEffect, useState } from "react";
import styles from "./commonsidebar.module.css";
import cx from "classnames";
import EachPeriod from "./EachPeriod";
import { getSessionsAPI, postSessionAPI } from "../../commonApi";
import Charging from "../../Charging";

const CommonSidebar = ({ category, sessionId, newSession }) => {
  const [open, setOpen] = useState(false);
  const [currentSession, setCurentsession] = useState("");
  const [fullSession, setFullsession] = useState([]);
  const [initialSession, setInitialSession] = useState([]);

  useEffect(() => {
    fecthData();
  }, [category]);

  useEffect(() => {
    // console.log("newSession- --useEffect--", newSession);
    if (newSession) {
      console.log("autoName is there.....................");
      autoName();
    }
  }, [newSession]);

  let body = { category: category };

  async function autoName() {
    try {
      setOpen(true);
      const res = await getSessionFun();
      setOpen(false);
      if (res.success) {
        setFullsession(res.sessions);
        setInitialSession(res.sessions);
        setCurentsession(newSession);
        sessionId(newSession);
      }
    } catch (e) {
      console.log("error--", e);
    }
  }

  async function getSessionFun() {
    try {
      setOpen(true);
      const res = await getSessionsAPI(body);
      setOpen(false);
      return res;
    } catch (e) {
      console.log("error--", e);
      setOpen(false);
    }
  }

  async function fecthData() {
    try {
      const res = await getSessionFun();
      console.log("getSessionFun----", res)
      if (res.success) {
        if (res.sessions.length === 0) {
          setOpen(true);
          const postSess = await postSessionAPI(body);
          setOpen(false);
          if (postSess.success) {
            setOpen(true);
            const getSess = await getSessionsAPI(body);
            setOpen(false);
            let v1 = Object.values(getSess.sessions[0]);
            setCurentsession(v1[0][0]);
            sessionId(v1[0][0]);
            setFullsession(getSess.sessions);
            setInitialSession(getSess.sessions);
          }
        } else {
          let v1 = Object.values(res.sessions[0]);
          setCurentsession(v1[0][0]);
          sessionId(v1[0][0]);
          setFullsession(res.sessions);
          setInitialSession(res.sessions);
        }
      }
    } catch (e) {
      console.log("error--", e);
      setOpen(false);
    }
  }

  async function createSession() {
    try {
      setOpen(true);
      const postSess = await postSessionAPI(body);
      setOpen(false);
      if (postSess.success) {
        fecthData();
      }
    } catch (e) {
      console.log("error--", e);
    }
  }

  function clickedSession(data) {
    setCurentsession(data);
    sessionId(data);
  }

  function searchChat(e) {
    let v1 = e.target.value.toLowerCase();
    console.log(v1);
    let filterChat = [];
    for (let i of initialSession) {
      let k1 = Object.keys(i);
      let eachMonth = {};
      eachMonth[k1[0]] = [];
      let arrayValue = Object.values(i);
      for (let x of arrayValue[0]) {
        if (x.toLowerCase().includes(v1)) {
          eachMonth[k1[0]].push(x);
        }
      }
      if (eachMonth[k1[0]].length > 0) {
        filterChat.push(eachMonth);
      }
    }
    if (v1.length === 0) {
      setFullsession(initialSession);
    } else {
      setFullsession(filterChat);
    }
  }

  function allData() {
    fecthData();
  }

  return (
    <>
      <Charging open={open} />
      <div className={styles.full}>
        <div className={styles.search}>
          <div onClick={() => createSession()} className={cx("commonbtn")}>
            <i className="fa fa-plus"></i>
            <span>New Chat</span>
          </div>
          <div className={cx("posrel")}>
            <input
              type="text"
              onChange={searchChat}
              autoComplete="off"
              placeholder="Serach.."
              className={styles.input}
            />
            <i
              className={cx(
                "fa fa-solid fa-magnifying-glass",
                styles.searchabs
              )}
            ></i>
          </div>
        </div>
        {fullSession?.map((item, idx) => {
          return (
            <EachPeriod
              key={idx}
              eachMonth={item}
              currentSession={currentSession}
              clickedSession={clickedSession}
              category={category}
              allData={allData}
            />
          );
        })}
      </div>
    </>
  );
};

export default CommonSidebar;
