import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";
import styles from "../Optilogistics/OptilogisticsSidebar.module.css";
import Popforrun from "../Optilogistics/Popupforrun.js"; // Import the Popforrun component
import play from "../../../assests/images/appstore/play.png";
import del from "../../../assests/images/appstore/del.svg";
import closebox from "../../../assests/images/pluginicons/closebox.png";
import monitor from "../../../assests/images/pluginicons/monitor.png";

import EachSession from "./EachSession.js";
import { DeleteRun, GetRuns, TrackRun } from "../../../commonApi.js";
import Charging from "../../../Charging";
import { Toast } from "primereact/toast";

const OptilogisticsSidebar = ({
  onManifestUpdate,
  onSelectedRunIdsChange,
  onFileNameChange,
  onActiveRunIdChange,
  onSessionNameChange,
}) => {
  const toast = useRef(null);
  const [openPopup, setOpenPopup] = useState(false); // State to control New Job popup visibility
  const [openTrackPopup, setOpenTrackPopup] = useState(false); // State to control Track popup visibility
  const [rundetails, setRundetails] = useState(null);
  const [runs, setRuns] = useState([]);
  const [selectedRunIds, setSelectedRunIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeRunId, setActiveRunId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [trackdetails, setTrackdetails] = useState(false);

  useEffect(() => {
    fetchRuns();
  }, []);

  useEffect(() => {
    if (runs.length === 0 && selectedRunIds.length > 0) {
      window.location.reload();
    }
  }, [runs, selectedRunIds]);
  const handleRunsSelected = (runIds) => {
    setSelectedRunIds(runIds);
    onSelectedRunIdsChange(runIds);
  };
  console.log("--------------------", selectedRunIds);

  const handleTrackRuns = async () => {
    if (selectedRunIds.length > 0) {
      const data = { run_ids: selectedRunIds };
      try {
        const result = await TrackRun(data);
        if (result.success) {
          setTrackdetails(result); // Assuming the track details are in the 'data' property
          setOpenTrackPopup(true); // Only open the popup after setting the details
        } else {
          alert(result.message || "Failed to track runs");
          setOpenTrackPopup(false);
        }
      } catch (error) {
        console.error("Error in TrackRun:", error);
        alert("Failed to process the request. Please try again.");
        setOpenTrackPopup(false);
      }
    } else {
      toast.current.show({
        // Show a toast message if no runs are selected
        severity: "info",
        summary: "Info",
        detail: "No runs selected.",
        life: 3000,
      });
    }
  };

  const fetchRuns = async () => {
    setOpen(true);
    const response = await GetRuns();
    setOpen(false);
    if (response.success) {
      setRuns(response.runs || []);
    } else {
      console.error(response.message || "Failed to fetch runs");
    }
  };

  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  // const toggleTrackPopup = () => {
  //   setOpenTrackPopup(!openTrackPopup);
  // };

  const handleRunDetailsUpdate = (details) => {
    setRundetails(details);
    fetchRuns();
  };
  const handleDeleteRuns = async () => {
    if (selectedRunIds.length > 0) {
      const data = { run_ids: selectedRunIds };

      try {
        setOpen(true);
        const result = await DeleteRun(data);
        setOpen(false);

        if (result.success) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Selected runs have been deleted successfully.",
            life: 3000,
          });
          fetchRuns();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: result.message || "Failed to delete runs",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Error in DeleteRun:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete runs. Please try again.",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "No runs selected.",
        life: 3000,
      });
    }
  };

  const handleClick = () => {
    if (selectedRunIds.length > 0) {
      setIsDeleting(true); // Trigger the animation only if runs are selected
      handleDeleteRuns(); // Proceed with deleting runs
      setTimeout(() => setIsDeleting(false), 2000); // Reset deletion state after animations
    } else {
      toast.current.show({
        // Show a toast message if no runs are selected
        severity: "info",
        summary: "Info",
        detail: "No runs selected.",
        life: 3000,
      });
    }
  };
  return (
    <>
      <Charging open={open} />
      <Toast ref={toast} />

      <div className={styles.full}>
        <div className={styles.search}>
          <div className={cx("commonbtn")} onClick={togglePopup}>
            <i className="fa fa-plus"></i>
            <span>New Job</span>
          </div>
          <div className={styles.comnbtn}>
            <button className="commonbtn" onClick={handleTrackRuns}>
              <img src={monitor} alt="Monitor" />
              Monitor
            </button>
            <button
              className={`${styles.button} ${isDeleting ? styles.delete : ""}`}
              onClick={handleClick}
            >
              <div className={styles.trash}>
                <div className={styles.top}>
                  <div className={styles.paper}></div>
                </div>
                <div className={styles.box}></div>
                <div className={styles.check}>
                  <svg viewBox="0 0 8 6">
                    <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                  </svg>
                </div>
              </div>
              <span>Delete</span>
            </button>
          </div>
        </div>

        <EachSession
          runs={runs}
          onManifestUpdate={onManifestUpdate}
          onRunsSelected={handleRunsSelected}
          onActiveRunIdChange={onActiveRunIdChange}
          onFileNameChange={onFileNameChange}
          onRunName={onSessionNameChange}
        />
      </div>
      {openPopup && (
        <Popforrun
          closeModel={() => setOpenPopup(false)}
          onRunDetailsUpdate={handleRunDetailsUpdate}
        />
      )}
      {openTrackPopup && (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <h6>
              Tracking Tokens
              <img
                src={closebox}
                onClick={() => setOpenTrackPopup(false)}
                alt="Close"
              />
            </h6>
          </div>

          <div className={styles.content}>
            <p>Prompt Tokens: {trackdetails?.prompt_tokens}</p>
            <p>Completions Tokens: {trackdetails?.completion_tokens}</p>
            <p>Total Tokens: {trackdetails?.total_tokens}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default OptilogisticsSidebar;
