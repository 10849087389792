import React, { useState, useRef, useEffect } from "react";
import styles from "../ContentGeneration/blogposter.module.css";
import generate from "../../assests/images/Blogposter/generate.png";
import { Generate_Tittle, GetTittle } from "../../commonApi";
import Charging from "../../Charging";
import { Toast } from "primereact/toast";

const Tittle = ({
  templateId,
  onTitleSelect,
  selectedTitle,
  generatedTitles,
  setGeneratedTitles,
}) => {
  const [userQuery, setUserQuery] = useState(selectedTitle || "");
  const [open, setOpen] = React.useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      try {
        const response = await GetTittle(templateId);
        if (response.success) {
          // You might want to do something with this data
          // For now, we're not using it as we're managing titles in the parent
        } else {
          console.error("Failed to fetch title data");
        }
      } catch (error) {
        console.error("Error fetching title data:", error);
      } finally {
        setOpen(false);
      }
    };

    fetchData();
  }, [templateId]);

  const handleInputChange = (e) => {
    setUserQuery(e.target.value);
  };

  const handleTitleClick = (title) => {
    setUserQuery(title);
    onTitleSelect(title);
  };

  const handleGenerateClick = async () => {
    setOpen(true);
    try {
      const data = { user_query: userQuery };
      const response = await Generate_Tittle(data);

      if (response.success) {
        setGeneratedTitles(response.titles);
        setUserQuery("");
        console.log("Generated Titles:", response.titles);
      } else {
        console.error("Failed to generate title:", response.message);
      }
    } catch (error) {
      console.error("Error generating title:", error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.title}>
        <input
          className={styles.inp}
          value={userQuery}
          type="text"
          placeholder="Select one of the tittles below"
          onChange={handleInputChange}
        />
        <div>
          <button className={styles.commonbtn2} onClick={handleGenerateClick}>
            <img src={generate} alt="Generate" />
            Generate
          </button>
        </div>
        <div>
          <h5>Suggestion Topic</h5>
          {generatedTitles.length > 0 ? (
            generatedTitles?.map((title, index) => (
              <div key={index}>
                <input
                  className={styles.inp2}
                  type="text"
                  readOnly
                  value={title}
                  onClick={() => handleTitleClick(title)}
                />
              </div>
            ))
          ) : (
            <p>No suggestions available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tittle;
