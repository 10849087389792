import React, { useRef, useState, useEffect } from "react";
import styles from "./ProjectCard.module.css";
import instagramIcon from "../../assests/images/Blogposter/instagram-icon.svg";
import Blogpen from "../../assests/images/Blogposter/Blogpen.png";
import {
  Contentdeletetemplate,
  GetTemplate,
  PostTemplate,
} from "../../commonApi"; // Import PostTemplate API
import { useParams } from "react-router-dom";
import toggle from "../../assests/images/Blogposter/toggle.png";
import Charging from "../../Charging";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const ProjectCRD = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [title, setTitle] = useState(""); // New field for title
  const [description, setDescription] = useState("");
  const { projectId } = useParams(); // Get the projectId from the URL
  const [optionsMenuIndex, setOptionsMenuIndex] = useState(null);
  const toast = useRef(null);

  const toggleOptionsMenu = (index, event) => {
    event.stopPropagation();
    setOptionsMenuIndex(optionsMenuIndex === index ? null : index);
  };
  useEffect(() => {
    if (projectId) {
      fetchTemplates(projectId); // Fetch templates when the component mounts
    }
  }, [projectId]); // Dependency on projectId ensures it runs when projectId changes

  const handleTemplateClick = (templateId) => {
    navigate(`/Blogposter/${templateId}`);
  };

  // API call to fetch templates
  const fetchTemplates = async (projectId) => {
    try {
      const response = await GetTemplate(projectId);
      if (response.success) {
        setOpen(true);

        setTemplates(response.data);
        console.log("---------------------", templates);
      } else {
        console.error("Failed to fetch templates");
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
    setOpen(false);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // API call to create a new template
  const handleCreateTemplate = async () => {
    const newTemplate = {
      template_name: templateName, // Correct key name for template_name
      template: title, // New field for title
      description: description, // Description
    };

    try {
      const response = await PostTemplate(newTemplate, projectId); // Call API with projectId
      if (response.success) {
        // Fetch the templates again after creating a new one
        fetchTemplates(projectId);
        setShowPopup(false); // Close popup
        setTemplateName("");
        setTitle(""); // Reset title field
        setDescription("");
      } else {
        console.error("Failed to create template");
      }
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  const handleDeleteTemplate = async (templateId, event) => {
    event.stopPropagation();
    try {
      const response = await Contentdeletetemplate(templateId);
      if (response.success) {
        // Directly update the templates state by filtering out the deleted template
        setTemplates((prevTemplates) =>
          prevTemplates.filter(
            (template) => template.template_unique_id !== templateId
          )
        );

        toast.current.show({
          severity: "success",
          summary: "Delete Success",
          detail: "Template deleted successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Delete Failed",
          detail: response.message,
          life: 4000,
        });
      }
    } catch (error) {
      console.error("Error deleting template:", error);
      toast.current.show({
        severity: "error",
        summary: "Delete Failed",
        detail: "An error occurred while deleting the template.",
        life: 4000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.main}>
        {showPopup && (
          <div className={styles.popupOverlay}>
            <div className={styles.popup}>
              <div className={styles.new}>
                <h5>New Template</h5>
              </div>
              <div className={styles.role}>
                <h6>Template Name</h6>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Template Name"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
                <h6>Title</h6>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <h6>Description</h6>
                <textarea
                  className={styles.textareaField}
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <div className={styles.butto}>
                  <button
                    className={styles.commonbtn2}
                    onClick={handleCreateTemplate}
                  >
                    Create
                  </button>
                  <button className={styles.skip} onClick={handleClosePopup}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <button className={styles.createButton1} onClick={handleOpenPopup}>
            Create Template
          </button>
          <div className={styles.innerMain}>
            {templates?.length > 0 ? (
              templates.map((template, index) => (
                <div
                  key={index}
                  className={styles.box}
                  onClick={() =>
                    handleTemplateClick(template.template_unique_id)
                  }
                >
                  <div className={styles.boxContent}>
                    <div className={styles.firstRow}>
                      <div>
                        <img src={instagramIcon} alt="instagramIcon" />
                      </div>
                      <div>
                        <p>{template.template_name}</p>
                      </div>
                      <div className={styles.toggleContainer}>
                        <img
                          src={toggle}
                          className={styles.more_options_button}
                          onClick={(event) => toggleOptionsMenu(index, event)}
                        />
                        {optionsMenuIndex === index && (
                          <div className={styles.options_menu}>
                            <div className={styles.edit_option}>Edit</div>
                            <div
                              className={styles.delete_option}
                              onClick={(event) =>
                                handleDeleteTemplate(
                                  template.template_unique_id,
                                  event
                                )
                              }
                            >
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.SecRow}>
                      <p>{template.description}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.centerContent}>
                <div className={styles.blgpen}>
                  <img src={Blogpen} alt="Blogpen" />
                </div>
                <p className={styles.infoText}>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document.
                </p>
                <button
                  className={styles.createButton}
                  onClick={handleOpenPopup}
                >
                  Create Template
                </button>
              </div> // Display if there are no templates
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCRD;
