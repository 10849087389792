import React, { useContext } from "react";
import styles from "./CreateRagModel.module.css";
import sidebarchaticondatadive from "../../../assests/images/commonsidebar/sidebarchaticondatadive.png";
import cx from "classnames";
// import SidebarOptions from './SidebarOptions';
import { IndexData } from "./DataDiveSidebar";

const EachRag = ({ data, index, changeSession, getSession, deleteOne }) => {
  const dataUsing = useContext(IndexData);

  function eachRagClick() {
    dataUsing.updateOptionsIndex(-1);
    changeSession(data?.imagechat_session);
  }

  return (
    <div
      onClick={() => eachRagClick()}
      className={cx(
        dataUsing?.currentSession === data?.imagechat_session &&
          styles.activeRag,
        styles.eachrag
      )}
    >
      <div className="flex gap12px alignCenter w90">
        <img className="w18px" src={sidebarchaticondatadive} alt="" />
        <h4 className={styles.ragName}>{data?.imagechat_session} </h4>
        <div className={styles.last}>
          <i
            className="fa-solid fa-trash"
            onClick={(e) => {
              e.stopPropagation();
              deleteOne(e,data.imagechat_session);
            }}
          ></i>
        </div>
      </div>
      {/* <div>
                                <i className="fa-solid fa-trash" onClick={(e) => {deleteSession(e, item) }}></i>
                            </div> */}
      {/* <SidebarOptions index={index} data1={data} getSession={getSession} /> */}
      {/* <i className="fa-solid fa-trash" onClick={() => { deleteOne(data) }}></i> */}
    </div>
  );
};

export default EachRag;
