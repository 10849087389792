import React, { useState, useRef } from "react";
import styles from "./CreateRagModel.module.css";
import Charging from "../../../Charging";
import { createSessionImagchatAPI } from "../../../commonApi";
import close from "../../../assests/images/model/close.png";
import upload from "../../../assests/images/folder/upload.png";
import success from "../../../assests/images/folder/success.png";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
const CreateRagModel = ({ closeModel, getData }) => {
  const [open, setOpen] = useState(false);
  const [modelName, setModelName] = useState("");
  const { ragName } = useParams();
  const navigate = useNavigate();
  const [uploadStatus, setUploadStatus] = useState("");
  const fileInputRef = useRef(null);
  const toast = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleButtonClick = () => {
    // Programmatically click the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    setUploadStatus("");
    // const files = event.target.files;
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

    for (let i = 0; i < files.length; i++) {
      if (!validImageTypes.includes(files[i].type)) {
        showError("Invalid file type. Please upload only images.");
        return;
      }
    }
    setSelectedFiles(files);
    // Assuming a successful upload process (you can add your actual upload logic here)
    setUploadStatus("Uploaded successfully.");
  };
  async function formSubmit(e) {
    try {
      e.preventDefault();
      setOpen(true);
      if (selectedFiles.length === 0) {
        console.log(
          "Please select at least one file to upload.",
          selectedFiles
        );
        showError("Please select at least one file to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("title", modelName);
      selectedFiles.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      const response = await createSessionImagchatAPI(formData);
      console.log("-------------------", response);

      if (response.success) {
        console.log("Upload successful:");
        setOpen(false);
        // setUploadStatus('Uploaded successfully.');
        // Handle navigation or other actions upon successful upload
        closeModel();
        getData();
      } else {
        throw new Error("Upload failed.");
      }
    } catch (error) {
      showError("Error uploading images:");
      // setUploadStatus('Upload failed. Please try again.');
    } finally {
      setOpen(false); // Stop the loading indicator
    }
  }

  // async function createSession() {
  //   try {
  //     let body = {
  //       datadive_session: modelName,
  //     };
  //     setOpen(true);
  //     const createSession = await createSessionDataDiveAPI(body);
  //     setOpen(false);
  //     console.log("createSession----", createSession);
  //     if (createSession?.success) {
  //       closeModel();
  //       getData();
  //       navigate(`/database/dragAndDropDataSource/${modelName}`);
  //     }
  //   } catch (e) {
  //     console.log("err---", e);
  //     setOpen(false);
  //   }
  // }
  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />

      <div className={styles.modal}>
        <div className={styles.modelBox}>
          <div className="justifyEnd">
            <img className="cursor" onClick={closeModel} src={close} alt="" />
          </div>
          <form onSubmit={formSubmit}>
            <div>
              <label className={styles.activeLabel} htmlFor="">
                {" "}
                New Chat{" "}
              </label>
            </div>
            <label className={styles.Label} htmlFor="">
              Title
            </label>
            <input
              className={styles.input}
              type="text"
              required
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className={styles.modelButton}>
              <button
                className={styles.access}
                type="button"
                onClick={handleButtonClick}
              >
                <img src={upload} alt="upload" /> Upload your images folder
              </button>
            </div>
            {uploadStatus && (
              <div className={styles.uploadStatusContainer}>
                <img
                  src={success}
                  alt="success"
                  className={styles.successIcon}
                />
                <p className={styles.uploadStatusText}>{uploadStatus}</p>
              </div>
            )}

            {/* {uploadStatus && <>  <img src={success} alt="success" /> <p  className={styles.greenclr}>{uploadStatus}</p> </>} */}
            <div className={styles.modelButton}>
              <button type="submit" className={styles.access}>
                Submit
              </button>
              <button onClick={closeModel} className={styles.cancel}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateRagModel;
