import React from 'react'

const SubmitArrow = ({ query }) => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 36 36" fill={query.length > 0 ? '#2D9BD5' : "#B1B1B1"}
                xmlns="http://www.w3.org/2000/svg"> <rect width="36" height="36" fill="#F5F5F5" />
                <g
                    clipPath="url(#clip0_0_1)">
                    <rect width="1920" height="1000" transform="translate(-1318 -920)" fill="#070D19" />
                    <rect x="-669" width="721" height="38" rx="6" fill="#161D2F" />
                    <path
                        d="M8.60547 7.88672V15.5651L19.1154 17.8234L8.60547 20.0817V27.7601L26.8227 17.8234L8.60547 7.88672Z"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_0_1">
                        <rect width="1920" height="1000" fill="white" transform="translate(-1318 -920)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default SubmitArrow