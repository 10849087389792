import React from "react";

import { useParams } from "react-router-dom";

const Poster = () => {
  const { templateId } = useParams();
  return <div>poster</div>;
};

export default Poster;
