import styles from "../../../components/chat/chat.module.css";
import downld from "../../../assests/images/texttoimage/dwnld.svg";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import roboIcon from "../../../assests/images/normalchat/roboIcon.png";
import Charging from "../../../Charging";
import {
  chat_Image_API,
  checkCredSessionAPI,
  getImageChatAPI,
  postautoGenerateAPI,
  putautoGenerateAPI,
} from "../../../commonApi";
import SubmitArrow from "../../../components/SubmitArrow";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../../commonFunction";

const DataDiveChat = ({ currentSession }) => {
  const [query, setQuery] = useState("");
  const [fullChat, setFullchat] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewchatbar, setViewchatbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentSession) {
      setViewchatbar(true);
      chatData();
    }
  }, [currentSession]);

  async function chatData() {
    try {
      console.log("--------------------", currentSession);
      let body = {
        title: currentSession,
        // "category": "ask your data"
      };

      setOpen(true);
      const getChat = await getImageChatAPI(body);
      setOpen(false);
      console.log("getChat--", getChat);
      if (getChat.success) {
        setFullchat(getChat.data);
        if (getChat.data?.length > 0) {
          scrollBottom();
        }
      }
    } catch (e) {
      console.log("error--", e);
      setOpen(false);
    }
  }

  async function chatWithLLm() {
    try {
      let b1 = {
        title: currentSession,
        question: query,
      };
      console.log("chatllm----b1--", b1);
      setOpen(true);
      const chatllm = await chat_Image_API(b1);
      setOpen(false);
      setQuery("");
      console.log("chatllm------", chatllm);
      if (chatllm) {
        chatData();
      }
    } catch (e) {
      setOpen(false);
    }
  }

  async function chatSubmit(e) {
    e.preventDefault();
    chatWithLLm();
  }

  function scrollBottom() {
    setOpen(true);
    setTimeout(() => {
      let mainchatting = document.getElementById("mainchatting");
      if (mainchatting) {
        mainchatting.scrollTo(0, mainchatting.scrollHeight);
      }
      setOpen(false);
    }, 500);
  }

  return (
    <>
      <Charging open={open} />
      <div className={styles.full1} id="mainchatting">
        <div className={styles.chat}>
          {fullChat.map((item, idx) => {
            // {Array(10).fill("").map((item, idx) => {
            return (
              <>
                <div key={idx} className={cx(styles.human)}>
                  <div className="flex w70 gap6px">
                    <div className="w8"></div>
                    <div className={styles.content}>
                      <div className={"justifyEnd"}>
                        {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti eveniet voluptates ipsam, porro enim impedit ullam, suscipit esse odio ut quidem in quos, incidunt praesentium vero eos adipisci molestiae sequi? */}
                        {/* {item?.genai_user[0]} */}
                        {item.question}
                      </div>
                      <div className={styles.time}>
                        {/* mani */}
                        {/* {item?.genai_user[1]} */}
                        {new Date(item.created_time).toLocaleString()}
                      </div>
                    </div>
                    <div className="w8">
                      <p> MP </p>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.robot)}>
                  <div className="flex w70 gap6px">
                    <div className="w8">
                      <img src={roboIcon} alt="" />
                    </div>
                    <div className={styles.content}>
                      <div className={"justifyStart colDir"}>
                        {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore atque dolore hic est voluptatem maiores rem saepe, voluptate, nisi ipsa doloribus exercitationem deleniti ut. Deleniti impedit expedita aut aspernatur unde. */}
                        {/* {item?.genai_bot[0][0]?.split('\n').map((item, idx123) => {
                          return (
                            <p key={idx123} className={styles.spaceChat} >{item} </p>
                          )
                        })} */}
                        {item.response_answer
                          .split("\n")
                          .map((line, idx123) => (
                            <p key={idx123} className={styles.spaceChat}>
                              {line}
                            </p>
                          ))}
                        Image Name: {item.image_name}
                        {item.response_image && (
                          <div className={styles.chatImageContainer}>
                            <div className={styles.imageWrapper}>
                              <img
                                src={`data:image/jpeg;base64,${item.response_image}`}
                                alt="Chat Response"
                                className={styles.chatImage}
                              />
                              <a
                                href={`data:image/jpeg;base64,${item.response_image}`}
                                download={item.image_name}
                                className={styles.downloadLink}
                              >
                                <img
                                  src={downld}
                                  className={styles.downloadIcon}
                                  alt="Download"
                                />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.time}>
                        {/* mass */}
                        {new Date(item.created_time).toLocaleString()}
                        {/* {item?.genai_bot[1]} */}
                      </div>
                    </div>
                    <div className="w8"></div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {viewchatbar && (
          <div className={cx(styles.serching1, "flexColCenter")}>
            <form className={styles.formChatData} onSubmit={chatSubmit}>
              <div className={styles.inpEnter}>
                <input
                  type="text"
                  name="searching"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  autoComplete="off"
                  placeholder="Enter your query..."
                />
                <button disabled={query.length > 0 ? false : true}>
                  <SubmitArrow query={query} />
                </button>
              </div>
            </form>
          </div>
        )}
        {!viewchatbar && (
          <div className={styles.greyname}>
            No chats found. Create a new chat or select an existing one
          </div>
        )}
      </div>
    </>
  );
};

export default DataDiveChat;
