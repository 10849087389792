import React from "react";
import { useEffect, useState, useRef } from "react";

import styles from "../Testautomation/TestAutomation.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TestautomationAPI } from "../../../commonApi";
import Charging from "../../../Charging";
import TestAutomation from "./TestAutomation";

const TestModel = ({ closeTest, saveTestDetails }) => {
  const [gitUrl, setGitUrl] = useState("");
  const [title, setTitle] = useState("");

  const [selectedTestCases, setSelectedTestCases] = useState({
    generate_functional_test_cases: false,
    generate_performance_test_cases: false,
    generate_unit_test_cases: false,
    generate_api_integration_test_cases: false,
    generate_user_acceptance_test_cases: false,
  });
  const [SelectedTestScripts, setSelectedTestScripts] = useState({
    generate_functional_test_scripts: false,
    generate_performance_test_scripts: false,
    generate_unit_test_scripts: false,
    generate_api_integration_test_scripts: false,
    generate_user_acceptance_test_scripts: false,
  });

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedTestCases((prevSelectedTestCases) => ({
      ...prevSelectedTestCases,
      [name]: checked,
    }));
  };

  const handleCheckboxChange1 = (event) => {
    const { name, checked } = event.target;
    setSelectedTestScripts((prevSelectedTestScripts) => ({
      ...prevSelectedTestScripts,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const testCasesArray = Object.keys(selectedTestCases).filter(
      (key) => selectedTestCases[key]
    );
    const testScriptsArray = Object.keys(SelectedTestScripts).filter(
      (key) => SelectedTestScripts[key]
    );

    const data = {
      gitUrl,
      title,
      testcases: testCasesArray,
      testscripts: testScriptsArray,
    };

    try {
      setOpen(true);
      const response = await TestautomationAPI(data);
      setOpen(false);
      console.log("Response from TestautomationAPI:", response);
      if (response.success) {
        closeTest();
        navigate("/appstore/testautomation", {
          state: {
            data: response,
            selectedTestCases: testCasesArray,
            selectedTestScripts: testScriptsArray,
          },
        });
      } else {
        console.log("error in Response from TestautomationAPI:");
      }
    } catch (error) {
      console.log("Error submitting test model:", error);
      setOpen(false);
    }
  };

  return (
    <div>
      <Charging open={open} />
      <div className={styles.modal} onClick={() => closeTest(false)}>
        <div className={styles.modelBox} onClick={(e) => e.stopPropagation()}>
          <form>
            <div className={styles.heading}>
              <h1>Test Automation</h1>
            </div>

            <div className={styles.git}>
              <h5>
                Title*
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className={styles.inp}
                  required
                />
              </h5>
            </div>
            <div className={styles.git}>
              <h5>
                GitHub URL*
                <input
                  required
                  type="text"
                  value={gitUrl}
                  onChange={(e) => setGitUrl(e.target.value)}
                  className={styles.inp}
                />
              </h5>
            </div>
            <div className={styles.heading2}>
              <h1>Test Cases</h1>
            </div>

            <div className={styles.inside}>
              <div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    name="generate_functional_test_cases"
                    checked={
                      selectedTestCases["generate_functional_test_cases"]
                    }
                  />
                  <h5> Functional Test</h5>
                </div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_user_acceptance_test_cases"
                    checked={
                      selectedTestCases["generate_user_acceptance_test_cases"]
                    }
                    onChange={handleCheckboxChange}
                  />
                  <h5>User Acceptance</h5>
                </div>
              </div>
              <div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_unit_test_cases"
                    checked={selectedTestCases["generate_unit_test_cases"]}
                    onChange={handleCheckboxChange}
                  />
                  <h5> Unit Test</h5>
                </div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_api_integration_test_cases"
                    checked={
                      selectedTestCases["generate_api_integration_test_cases"]
                    }
                    onChange={handleCheckboxChange}
                  />
                  <h5>API Integration</h5>
                </div>
              </div>
              <div className={styles.testcases}>
                <input
                  type="checkbox"
                  name="generate_performance_test_cases"
                  checked={selectedTestCases["generate_performance_test_cases"]}
                  onChange={handleCheckboxChange}
                />
                <h5> Performance Test</h5>
              </div>
            </div>
            <div className={styles.heading2}>
              <h1>Test scripts</h1>
            </div>
            <div className={styles.inside}>
              <div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_functional_test_scripts"
                    checked={
                      selectedTestCases["generate_functional_test_scripts"]
                    }
                    onChange={handleCheckboxChange1}
                  />
                  <h5> Functional Test</h5>
                </div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_user_acceptance_test_scripts"
                    checked={
                      SelectedTestScripts[
                        "generate_user_acceptance_test_scripts"
                      ]
                    }
                    onChange={handleCheckboxChange1}
                  />
                  <h5>User Acceptance</h5>
                </div>
              </div>
              <div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_unit_test_scripts"
                    checked={SelectedTestScripts["generate_unit_test_scripts"]}
                    onChange={handleCheckboxChange1}
                  />
                  <h5> Unit Test</h5>
                </div>
                <div className={styles.testcases}>
                  <input
                    type="checkbox"
                    name="generate_api_integration_test_scripts"
                    checked={
                      SelectedTestScripts[
                        "generate_api_integration_test_scripts"
                      ]
                    }
                    onChange={handleCheckboxChange1}
                  />
                  <h5>API Integration</h5>
                </div>
              </div>
              <div className={styles.testcases}>
                <input
                  type="checkbox"
                  name="generate_performance_test_scripts"
                  checked={
                    SelectedTestScripts["generate_performance_test_scripts"]
                  }
                  onChange={handleCheckboxChange1}
                />
                <h5> Performance Test</h5>
              </div>
            </div>

            <div className={styles.modelButton}>
              <button
                onClick={(e) => handleSubmit(e)}
                className={styles.generate}
              >
                Generate
              </button>
              <button onClick={closeTest} className={styles.cancel}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TestModel;
