import React, { useEffect, useState, useRef } from "react";
import styles from "../../appstore/AutomationTest/ProjectCard.module.css";
import vectorImage from "../../../assests/images/appstore/upload.png";
import card_icon from "../../../assests/images/appstore/Card.png";
import options from "../../../assests/images/appstore/options.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Toast } from "primereact/toast";

import {
  Delete_Project,
  generate_test_cases,
  get_all_features,
  Post_test_automation_projects,
  test_automation_projects,
} from "../../../commonApi";
import Charging from "../../../Charging";

const TestProjects = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);

  const toast = useRef(null);

  const [newProject, setNewProject] = useState({
    projectName: "",
    projectURL: "",
    file: null,
  });

  useEffect(() => {
    fetchProjects();
  }, []);

  const navigate = useNavigate();
  const [optionsMenuIndex, setOptionsMenuIndex] = useState(null);

  const handleProjectClick = async (project, event) => {
    event.stopPropagation(); // This ensures that clicking on options does not trigger navigation
    const data = { project_id: project.project_id }; // Send project_id as a string
    const response = await get_all_features(data);
    if (response.success) {
      console.log("Features fetched successfully:", response.data);
      navigate(
        `/appstore/TestAutomation2/projectdetails/${project.project_id}`,
        {
          state: {
            projectData: project,
          },
        }
      );
    } else {
      console.error("Failed to fetch features:", response.message);
    }
  };

  const handleDeleteProject = async (projectId, event) => {
    event.stopPropagation();
    const data = {
      project_id: projectId.toString(),
    };
    const response = await Delete_Project(data);
    if (response.success) {
      fetchProjects();

      toast.current.show({
        severity: "success",
        summary: "Delete Success",
        detail: "Project deleted successfully",
        life: 3000,
      });
      // Refresh the list of projects
    } else {
      toast.current.show({
        severity: "error",
        summary: "Delete Failed",
        detail: response.message,
        life: 4000,
      });
    }
  };
  const toggleOptionsMenu = (index, event) => {
    event.stopPropagation();
    setOptionsMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setNewProject({
      ...newProject,
      file: e.target.files[0],
    });
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const fetchProjects = async () => {
    setOpen(true);

    try {
      const response = await test_automation_projects();
      if (response.success) {
        // Directly using response.success assuming the function unwraps one layer
        setProjects(response.data); // Correctly setting just the data array
        console.log("Projects fetched:", response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      showError(error);

      console.error("Failed to fetch projects:", error);
    }
    setOpen(false);
  };

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };

  const handleCreateProject = async () => {
    if (newProject.projectName && newProject.projectURL && newProject.file) {
      const formData = new FormData();
      formData.append("url", newProject.projectURL);
      formData.append("application_name", newProject.projectName);
      formData.append("files", newProject.file); // Make sure the key matches expected backend key
      setShowModal(false);
      setOpen(true);

      try {
        const response = await Post_test_automation_projects(formData);

        if (response.success) {
          console.log("Project created successfully:", response);
          // Refetch projects to update the list
          fetchProjects();
          setNewProject({ projectName: "", projectURL: "", file: null }); // Reset form state
          // Close modal
        } else {
          console.error("Failed to create project:", response.message);
        }
      } catch (error) {
        console.error("Error creating project:", error);
      }
    } else {
      alert("Please fill all fields and upload a file."); // Basic validation feedback
    }
    setOpen(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.dashboard}>
        <div className={styles.all}>
          <div className={styles.box} onClick={openModal}>
            <p>+ Create New Project</p>
          </div>

          {projects.map((project, index) => (
            <div
              className={styles.project_info}
              key={index}
              onClick={(event) => handleProjectClick(project, event)}
            >
              <div className={styles.firstRow}>
                <div className={styles.childRow}>
                  <div className={styles.imggback}>
                    <img
                      src={card_icon}
                      alt="Project Icon"
                      className={styles.card_icon}
                    />
                  </div>
                  <h3 className={styles.info_header}>
                    {project.application_name}
                  </h3>
                </div>
                <img
                  src={options}
                  alt="Options Icon"
                  className={styles.options_icon}
                  onClick={(event) => toggleOptionsMenu(index, event)}
                />
              </div>
              {optionsMenuIndex === index && (
                <div className={styles.options_menu}>
                  {/* <div
                    className={styles.edit_option}
                    onClick={(event) => {
                      event.stopPropagation();
                      console.log("Edit functionality here");
                    }}
                  >
                    Edit
                  </div> */}
                  <div
                    className={styles.delete_option}
                    onClick={(event) =>
                      handleDeleteProject(project.project_id, event)
                    }
                  >
                    Delete
                  </div>
                </div>
              )}
              <p className={styles.url_p}>
                URL:
                <input
                  className={styles.info_input}
                  type="text"
                  value={project.url}
                  readOnly
                />
              </p>
              {project.test_plan_document ? (
                <p className={styles.url_p1}>
                  <span className={styles.testPlanLabel}>Test Plan:</span>
                  <span className={styles.fileName}>
                    {project.test_plan_document}
                  </span>
                </p>
              ) : (
                <p>No file uploaded</p>
              )}
            </div>
          ))}

          {/* Modal for creating/updating project */}
          <div className={styles.body}>
            <Modal open={showModal} onClose={closeModal}>
              <div className={styles.modal_overlay}>
                <div className={styles.overlay_header}>
                  <h5>Create New Project</h5>
                  <p className={styles.close_btn} onClick={closeModal}>
                    X
                  </p>
                </div>
                <div>
                  <div
                    className={styles.form}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleCreateProject();
                    }}
                  >
                    <div className={styles.form_field}>
                      <p className={styles.projectname}>Application Name</p>
                      <input
                        className={styles.inputbox_Name}
                        placeholder="Application Name"
                        type="text"
                        value={newProject.projectName}
                        onChange={(e) =>
                          setNewProject({
                            ...newProject,
                            projectName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className={styles.form_field}>
                      <p className={styles.projectURL}>
                        URL of the Application
                      </p>
                      <input
                        className={styles.inputbox_URL}
                        placeholder="URL of the application"
                        type="url"
                        value={newProject.projectURL}
                        onChange={(e) =>
                          setNewProject({
                            ...newProject,
                            projectURL: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.upload_section}>
                    <div className={styles.upload_button}>
                      <button
                        className={styles.fileUploadButton}
                        type="button"
                        onClick={handleFileUploadClick}
                      >
                        <img
                          src={vectorImage}
                          alt=""
                          className={styles.buttonIcon}
                        />
                        Upload file
                      </button>
                    </div>
                    <div className={styles.selected_file_text}>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept=".pdf, .doc, .docx, .txt"
                      />
                      {newProject.file ? (
                        <p className={styles.selected_file_name}>
                          Selected file: {newProject.file.name}
                        </p>
                      ) : (
                        <p className={styles.no_file_selected}>
                          No file selected
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.modal_buttons}>
                    <button
                      className={styles.submit_btn}
                      type="submit"
                      onClick={handleCreateProject}
                    >
                      Submit
                    </button>
                    <button
                      className={styles.cancel_btn}
                      type="button"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProjects;
