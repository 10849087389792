import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link, json } from "react-router-dom";
import styles from "../projects/projectsoverview.module.css";
import datasourceImg from "../../../assests/images/datavizard/datasources.png";
import projectImg from "../../../assests/images/datavizard/datasources.png";
import { getSpecificProjectChartAPI } from "../../../commonApi";
import { useLocation } from "react-router-dom";
import "../projects/projectdatavizard.css";
import PlotGraph from "../../../GraphDatabase/PlotGraph";

const Projectsoverview = () => {
  const { projectName } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { state } = useLocation();
  const { project_id, datasource_id, graph } = state;

  console.log(datasource_id, "---Projectsoverview---");

  let eachjson = JSON.parse(graph || "{}");

  const fetchData = async () => {
    try {
      const response = await getSpecificProjectChartAPI(project_id);
      if (response.status && response.data.length > 0) {
        setData(response.data);
      } else {
        // Handle the case where no data is fetched
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [projectName]);

  const handelAddChart = () => {
    navigate(
      `/appstore/datavizard/projects/projectquestion/${projectName}/${datasource_id}`,
      {
        state: { project_id: project_id, datasource_id: datasource_id },
      }
    );
  };

  function chartClicked(data) {
    navigate(`/appstore/datavizard/projects/chartAndTables/${projectName}/${datasource_id}`, {
      state: { data: data, datasource_id: datasource_id },
    });
  }

  console.log("data---", data);

  function handleDrop(e, already) {
    console.log(e.dataTransfer);

    let gettingData = JSON.parse(e.dataTransfer.getData("component"));

    // console.log('already data------', already);
    // console.log(`gettingData------`, JSON.parse(gettingData));

    let a, b

    let copyData = JSON.parse(JSON.stringify(data));
    for (let i = 0; i < copyData.length; i++) {
      if (copyData[i].id === already.id) {
        a = i;
      }
      if (copyData[i].id === gettingData.id) {
        b = i;
      }
    }
    copyData[a] = gettingData;
    copyData[b] = already;

    setData(copyData);
  }

  function handleDragStart(e, data) {
    console.log("handleDragStart------", data);
    e.dataTransfer.setData("component", JSON.stringify(data));
  }

  function handleOnDragOver(e) {
    e.preventDefault();
  }

  return (
    <>
      {!data?.length && !eachjson.data?.length ? (
        <div>
          <div className={styles.heading}>
            <h5>{projectName}</h5>
          </div>

          <div className={styles.over}>
            <div className={styles.overview}>
              <h2 className={styles.headline}>Overview</h2>
              <button className="activeBtn" onClick={handelAddChart}>
                Add chart
              </button>
            </div>
            <div className={styles.chart} onClick={handelAddChart}>
              <h5>+ Add Chart</h5>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.ifdataexixts}>
          <div className="w15">
            <ul className="datavizardSidebar">
              <Link to="/appstore/datavizard/projects">
                <li className="active">
                  <img src={projectImg} alt="" /> Projects
                </li>
              </Link>
              <Link to="/appstore/datavizard/datasource">
                <li>
                  <img src={datasourceImg} alt="" /> Datasources
                </li>
              </Link>
            </ul>
          </div>
          <div className="w85 pad32">
            <div className={styles.headingg}>
              <h5>{projectName}</h5>
            </div>
            <div className="justifyBetween alignCenter borderBottom marBot8px">
              <h2 className={styles.headline}>Overview</h2>
              <button className="activeBtn" onClick={handelAddChart}>
                Add chart
              </button>
            </div>
            <div className="h79dvh flex gap8px flexWrap">
              {data?.map((ele, idx) => {
                let chartcreate = JSON.parse(ele.chart);
                return (
                  <div key={idx} className="w48"
                    draggable
                    onDragStart={(e) => handleDragStart(e, ele)}
                    onDrop={(e) => handleDrop(e, ele)}
                    onDragOver={handleOnDragOver}
                    onClick={() => chartClicked(ele)}
                  >
                    <PlotGraph
                      data={chartcreate?.data}
                      layout={chartcreate?.layout}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Projectsoverview;
