// LOCAL
// const backend_url = "http://127.0.0.1:8000";
//  const frontend_url = "http://127.0.0.1:8000";

// // DEMO;
const frontend_url = "https://llmops.mlangles.ai";
const backend_url = "https://llmops.mlangles.ai";
const backend_url5000 = "https://llmops.mlangles.ai/rag_qa";
const backend_url8001 = "https://llmops.mlangles.ai/dataviz";
const dataVizard = "https://llmops.mlangles.ai/dataviz";
const Optilogistics_url = "https://llmops.mlangles.ai/opti_qa";
// const backend_url5000 = 'https://llmops.mlangles.ai:5000';
// const backend_url8001 = 'https://llmops.mlangles.ai:8001';

// testing
// const frontend_url = 'http://172.31.75.131';
// const backend_url = 'http://172.31.75.131';
// const backend_url5000 = "http://172.31.24.95:5000";
// const backend_url8001 = "http://172.31.24.95:8001";

// DEV-2
// const frontend_url = "http://172.31.64.180";
// const backend_url = "http://172.31.64.180";
// const Optilogistics_url = "http://172.31.64.180:8080/opti_qa";
const Content_url = "http://172.31.75.131:8081";

// const backend_url5000 = "http://172.31.24.95:5000";
// const backend_url8001 = "http://172.31.24.95:8001";

//Trial platform
// const frontend_url = 'http://52.21.253.123';
// const backend_url='http://52.21.253.123';
// const backend_url5000 = 'http://172.31.24.95:5000';
// const backend_url8001 = 'http://172.31.24.95:8001';

// const dataVizard = "http://172.31.64.180:7000";

// dev
// const frontend_url = 'http://172.31.24.95';
// const backend_url = 'http://172.31.24.95';

// const backend_url5000 = 'http://172.31.24.95';
// const backend_url8001 = 'http://172.31.24.95:8001';
//const dataVizard = "http://172.31.89.163:7000";

// demo
// const frontend_url = "http://172.31.38.101";
// const backend_url = "http://172.31.38.101";
// const backend_url5000 = "http://172.31.24.95:5000";
// const backend_url8001 = "http://172.31.24.95:8001";
// const dataVizard = "http://172.31.89.163:7000";

// const backend_url = 'http://54.164.214.124';
// const frontend_url = 'http://54.164.214.124';

// DEV public IP
// const backend_url = 'http://54.158.159.88';
// const frontend_url = 'http://54.158.159.88';

// DEV private IP
// const backend_url = 'http://172.31.24.95';
// const frontend_url = 'http://172.31.24.95';

// const backend_url = "http://172.20.251.235";
// const frontend_url = "http://172.20.251.235";
// const frontend_url = 'http://172.31.75.131/';
// const dataVizard = "http://172.31.75.131/";
// const backend_url="http://172.31.75.131/";

export {
  backend_url,
  frontend_url,
  backend_url5000,
  backend_url8001,
  dataVizard,
  Optilogistics_url,
  Content_url,
};
