import React, { useState } from "react";
import send from "../../../assests/images/datavizard/send.png";
import styles from "../projects/create.module.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  generateGraphAPI,
  generateSQLAPI,
  generateTableAPI,
  createChartAPI,
} from "../../../commonApi";
import { updatingLoading } from "../../../store/slice/loadingAndError";
import { useDispatch } from "react-redux";

const CreateQuestion = () => {
  const [ques, setQues] = useState("");
  const [titlee, setTitlee] = useState("");
  const { projectName } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(`state-------`, state);

  const { project_id, datasource_id } = state;
  const dispatch = useDispatch();

  console.log("state-", datasource_id);

  const generateSql = async () => {
    try {
      dispatch(updatingLoading(true));
      const askQuestion = await generateSQLAPI(datasource_id, {
        question: ques,
      });
      dispatch(updatingLoading(false));
      console.log(`askQuestion--${askQuestion}`);
      if (askQuestion?.status) {
        executeSql(askQuestion?.sql);
      }
    } catch (error) {
      console.error("Error posting question:", error);
    }
  };

  const executeSql = async (data) => {
    try {
      dispatch(updatingLoading(true));
      let body = {
        query: data,
      };
      const generateTable = await generateTableAPI(datasource_id, body);
      dispatch(updatingLoading(false));
      console.log(`askQuestion--${generateTable}`);
      if (generateTable?.status) {
        generateGraphFun(data);
      }
    } catch (error) {
      console.error("Error posting question:", error);
    }
  };

  const generateGraphFun = async (d1) => {
    try {
      dispatch(updatingLoading(true));
      let body = {
        sql: d1,
        question: ques,
      };
      const generateGraph = await generateGraphAPI(datasource_id, body);
      dispatch(updatingLoading(false));
      console.log(`askQuestion--${generateGraph}`);
      if (generateGraph?.status) {
        console.log("bfihvbflknjks---");
        createChartFunction(d1, generateGraph.data);
      }
    } catch (e) {
      console.log(`error---${e}`);
    }
  };

  const createChartFunction = async (query, chart) => {
    try {
      console.log("first-------");
      dispatch(updatingLoading(true));
      let body = {
        project_id: project_id,
        chart_title: titlee,
        chart_question: ques,
        sql_query: query,
        chart: chart,
      };
      console.log("body---->", body);
      const createChart = await createChartAPI(body);
      dispatch(updatingLoading(false));
      if (createChart.status) {
        navigate(
          `/appstore/datavizard/projects/chartAndTables/${projectName}/${datasource_id}`,
          {
            state: {
              datasource_id: datasource_id,
              data: body,
            },
          }
        );

        // navigate(
        //   `/appstore/datavizard/projects/projectsoverview/${projectName}`,
        //   {
        //     state: {
        //       project_id: project_id,
        //     },
        //   }
        // );
      }
    } catch {}
  };

  const handleSubmit = (e) => {
    console.log("oiuhgfc");
    e.preventDefault();
    generateSql();
  };

  return (
    <div className={styles.main}>
      <div className={styles.heading}>
        <h5>{projectName}</h5>
      </div>
      <div className={styles.CreateQuestion}>
        <form onSubmit={handleSubmit}>
          <div className="flexColCenter">
            <h4 className="f16400px">
              Ask a question!
            </h4>
            <div className="w20vw posRel">
              <input
                type="text"
                className="blackInput"
                value={titlee}
                onChange={(e) => setTitlee(e.target.value)}
                placeholder="Enter Chart Title"
              />
              {/* <img
                src={send}
                alt=""
                className="posSendBtn"
                onClick={handleSubmit}
              /> */}
            </div>
            <div className="w40vw posRel">
              <input
                type="text"
                className="blackInput"
                value={ques}
                onChange={(e) => setQues(e.target.value)}
                placeholder="Ask a question"
              />
              {/* <img
                src={send}
                alt=""
                className="posSendBtn"
                onClick={handleSubmit}
              /> */}
            </div>
            <button onClick={handleSubmit} className="activeBtn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateQuestion;
