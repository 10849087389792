import CommonTitleBar from "../management/CommonTitleBar";

import styles from "../management/user.module.css";
import plus from "../../assests/images/logo/plus.png";
import { FormControl, Select, circularProgressClasses } from "@mui/material";
import cx from "classnames";
import { create_role } from "../../commonApi";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export const Rolecreate = () => {
  const [rolename, setRolename] = useState("");
  const [open, setOpen] = useState(false);
  const [createRole, setCreateRole] = useState(false);
  const navigate = useNavigate();

  async function createRoleBtn(e) {
    e.preventDefault();
    console.log("rolename---");
    if (rolename.length === 0) {
      // funError("Enter Role Name");
    } else {
      const radiocheck = document.getElementsByClassName("policesName");

      let newData = {};
      newData = { role_name: rolename };
      for (let i of radiocheck) {
        if (i.checked) {
          newData = { ...newData, [i.name]: "1" };
        } else {
          newData = { ...newData, [i.name]: "0" };
        }
      }
      try {
        setOpen(true);
        const createrole = await create_role(newData);
        setOpen(false);

        if (createrole.success) {
          console.log("create role-----------", createrole);
          // setCreateRole(false);
          setRolename("");
          navigate("/roleManagement");
          // fetchData();
        } else {
          setCreateRole(false);
          setRolename("");
          // navigate("/rolemanage/false");
          // fetchData();
        }
      } catch (e) {
        // showError("There is an Error--", e);
      }
      console.log("--", newData);
    }
  }

  return (
    <div>
      <CommonTitleBar />
      <div className={styles.inside_newuser}>
        <form>
          <div className={styles.d_flex}>
            <label className={styles.role}>Role Name</label>
            <input
              autoComplete="off"
              type="text"
              value={rolename}
              onChange={(e) => setRolename(e.target.value)}
              className={styles.roleinput}
            />
          </div>
          <div className={styles.policies}>
            <h6 className={styles.selectpolicy}>Select Policies</h6>
            <table>
              <div className={styles.table_width50}>
                <div className={styles.module_right}> Modules Available</div>
                <div className={styles.policyspace}>Policies </div>
              </div>
              <div className={styles.insidetable}>
                {/* <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label> User Management </label>
                  </div>

                  <div className={styles.right_width60}>
                    <div>
                      <div className={styles.eachcheck}>
                        <input
                          autoComplete="off"
                          type="checkbox"
                          name="user_management_roles_admin"
                          className={cx(
                            styles.radioChecking,
                            styles.adioChecking1
                          )}
                          value="1"
                        />
                        <label htmlFor="">Assign/Modify user roles </label>
                      </div>
                      <div className={styles.eachcheck}>
                        <input
                          autoComplete="off"
                          type="checkbox"
                          name="user_management_roles_admin"
                          className={cx("radioChecking1",
                            styles.radioChecking,
                            styles.adioChecking1
                          )}
                          value="1"
                        />
                        <label htmlFor="">
                         Access to User Management
                        </label>
                      </div>
                      <div className={styles.eachcheck}>
                        <input
                          autoComplete="off"
                          type="checkbox"
                          name="user_management_roles_admin"
                          className={cx("radioChecking1",
                            styles.radioChecking,
                            styles.adioChecking1
                          )}
                          value="1"
                        />
                        <label htmlFor="">None</label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label> Smart Chat </label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="smart_chat"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">Access to Smart Chat</label>
                    </div>
                    {/* <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx(
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">Edit/modify my projects</label>
                    </div>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx(
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">Edit/modify assigned projects</label>
                    </div>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx(
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">None</label>
                    </div> */}
                  </div>
                </div>
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label>Data Dive</label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="data_dive"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor=""> Access to Data Dive</label>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label>App Hub</label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx("radioChecking1",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor=""> Access to App Hub</label>
                    </div>
                  </div>
                </div> */}
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label> Model Opt </label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="model_opt"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor=""> Access to Model Opt</label>
                    </div>
                    {/* <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx(
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">Edit/modify runs</label>
                    </div>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="user_management_roles_admin"
                        className={cx(
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">None</label>
                    </div> */}
                  </div>
                </div>
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label>Model Fine Tune</label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="model_fine_tuning"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor=""> Access to Model Fine Tune</label>
                    </div>
                  </div>
                </div>
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label>Hub Plus</label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="hub_plus"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor="">Access to Hub Plus</label>
                    </div>
                  </div>
                </div>
                <div className={styles.insidetable_width50}>
                  <div className={styles.left_width40}>
                    <label>App Design</label>
                  </div>
                  <div className={styles.right_width60}>
                    <div className={styles.eachcheck}>
                      <input
                        autoComplete="off"
                        type="checkbox"
                        name="app_design"
                        className={cx(
                          "radioChecking1 policesName",
                          styles.radioChecking,
                          styles.adioChecking1
                        )}
                        value="1"
                      />
                      <label htmlFor=""> Access to App Design</label>
                    </div>
                  </div>
                </div>
              </div>
            </table>
            <div className={styles.create_btn}>
              <div
                className={styles.commonbtn}
                type="submit"
                onClick={(e) => createRoleBtn(e)}
              >
                Create user role
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
