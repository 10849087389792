import React, { useState } from 'react'
import DataDiveSidebar from './DataDiveSidebar'
import DataDiveChat from './DataDiveChat'

const Imagechat = () => {

  const [curSession, setCurSession] = useState("");

  function updateSession(data) {
    setCurSession(data)
  }

  return (
    <div className='flex'>
      <DataDiveSidebar updateSession={updateSession} currentSession={curSession} />
      <DataDiveChat currentSession={curSession} />
    </div>
  )
}

export default Imagechat;