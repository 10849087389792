import React, { useEffect, useRef } from 'react';
import styles from "./dragAndDrop.module.css";

const DragAndDrop = () => {
    const drop = useRef(null);
    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        return () => {
            drop.current.removeEventListener('dragover', handleDragOver);
            drop.current.removeEventListener('drop', handleDrop);
        }
    }, []);

    async function fileChange1(e) {
        try {
            console.log("fileChange1");
            let f1 = e.target.files;
            // let f1 = e.target.files[0];
            console.log("f1 ------", f1);
            // setFile1(e.target.files);

            const formData = new FormData();
            for (let i of f1) {
                formData.append("files", i);
            }
            formData.append("session_id", "currentTab");
            formData.append("user_account_id", "userDetails");

            console.log("formData----", formData);

        }
        catch (e) {
            console.log('error--', e);
        }
    }

    function handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation()
    }

    function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        const data = e.dataTransfer.files;
        console.log('files----->', data);
    }

    return (
        <div className={styles.full}>
            <div className={styles.flexCenter}>
                <h2>Custom File and Folder Upload</h2>
                <div className={styles.box} ref={drop} >
                    File Upload
                </div>

                {/* react-uploader  PACKAGE */}
                <div className={styles.box}>
                    Folder Upload
                </div>
                

            </div>
            <div className={styles.flexCenter}>
                <h2>Default File and Folder Upload</h2>
                <div className={styles.box}>
                    File Upload
                    <input type="file" onChange={fileChange1} />
                </div>
                <div className={styles.box}>
                    Folder Upload
                    <input type="file" webkitdirectory="true" directory="true" mozdirectory="true" onChange={fileChange1} className='fileChange1' />
                </div>
            </div>
        </div>
    )
}

export default DragAndDrop