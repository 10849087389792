import React, { useEffect, useState } from "react";
import styles from "./commonsidebar.module.css";
import secIcon from "../../assests/images/commonsidebar/secIcon.png";
import cx from "classnames";
import Charging from "../../Charging";
import { deleteSessionAPI } from "../../commonApi";

const EachSections = ({
  eachSession,
  currentSession,
  presentSession,
  category,
  refresh,
}) => {
  const [eachOne, setEachone] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setEachone(eachSession);
  }, [eachSession]);

  async function deleteSession(e, sessionId) {
    try {
      e.stopPropagation();
      setOpen(true);
      // console.log('sessionId---', sessionId);
      let body = {
        category: category,
        session_id: sessionId,
      };
      // console.log('body----->', body);
      const deleteSession = await deleteSessionAPI(body);
      setOpen(false);
      // console.log('deleteSession--->', deleteSession);
      if (deleteSession.success) {
        refresh();
      } else {
        console.log("error");
      }
    } catch (e) {}
  }

  return (
    <>
      <Charging open={open} />
      {eachOne?.map((item, idx) => {
        // console.log('item------->', item, "----", eachSession);
        return (
          <div
            key={idx}
            onClick={() => presentSession(item)}
            className={cx(
              styles.eachSec,
              currentSession === item ? styles.active : ""
            )}
          >
            <div>
              <img src={secIcon} alt="" />
            </div>
            <div className={cx(styles.flexCol, "gap4 w80")}>
              <h5 className={cx("dotss", styles.tit)}>{item}</h5>
              {/* <h6 className={styles.con}>Data Scientist</h6> */}
            </div>
            <div>
              <i
                className="fa-solid fa-trash"
                onClick={(e) => {
                  deleteSession(e, item);
                }}
              ></i>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EachSections;
