import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import styles from "../../screens/hubplus/index.module.css";
import styles from "./user.module.css";
// import plus from "../assests/images/logo/plus.png";

const CommonTitleBar = () => {
  let a1 = window.location.href.split("/");
  console.log(a1[3]);
  return (
    <div className={styles.main}>
      <Link to="/userManagement">
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "userManagement" ? "green" : ""
          } `}
        >
          USER MANAGEMENT
        </h4>
      </Link>
      <Link to="/roleManagement">
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "roleManagement" ? "green" : ""
          } `}
        >
          ROLE MANAGEMENT
        </h4>
      </Link>
      <Link to="/businessManagement">
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "businessManagement" ? "green" : ""
          } `}
        >
          BUSINESS UNIT    
        </h4>
      </Link>
    </div>
  );
};

export default CommonTitleBar;
