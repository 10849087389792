import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "../Optilogistics/Eachsession.module.css";
import { EachRunApi, GetRuns } from "../../../commonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EachSession = ({
  runs,
  onManifestUpdate,
  onRunsSelected,
  onActiveRunIdChange,
  onFileNameChange,
  onRunName,
}) => {
  const [activeRunId, setActiveRunId] = useState(null);
  const [selectedRuns, setSelectedRuns] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [open, setOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    fetchRuns();
  }, [dateRange, customDateRange.start, customDateRange.end]); // Fetch runs based on date range and custom date range

  useEffect(() => {
    // Auto-select the first run
    if (runs.length > 0) {
      handleRunClick(runs[0].run_id);
    }
  }, [runs]);

  const handleDateRangeChange = (event) => {
    const selectedValue = event.target.value;
    setDateRange(selectedValue);
    // Toggle the date picker visibility based on the selection of 'custom'
    setShowDatePicker(selectedValue === "custom");
  };

  const handleDropdownClick = (event) => {
    if (event.target.value === "custom") {
      setShowDatePicker(true); // Always open the DatePicker when 'Custom Range' is selected
    } else {
      setShowDatePicker(false); // Close the DatePicker if another option is selected
    }
  };

  const handleCustomDateChange = (dates) => {
    const [start, end] = dates;
    setCustomDateRange({ start, end });
    if (start && end) {
      setShowDatePicker(false); // Automatically close the date picker once both dates are selected
    }
  };

  const fetchRuns = async () => {
    setOpen(true);
    const response = await GetRuns();
    setOpen(false);
    if (response.success) {
      // setRuns(response.runs || []);
    } else {
      console.error(response.message || "Failed to fetch runs");
    }
  };

  const filteredRuns = runs.filter((run) => {
    const runDate = new Date(run.date);
    if (
      dateRange === "custom" &&
      customDateRange.start &&
      customDateRange.end
    ) {
      return runDate >= customDateRange.start && runDate <= customDateRange.end;
    }
    return true; // Adjust based on other date ranges
  });

  const handleRunClick = async (run_id) => {
    const run = runs.find((run) => run.run_id === run_id);
    setActiveRunId(run_id);
    onActiveRunIdChange(run_id);
    if (run) {
      onRunName(run.session_name); // Send the session name to the parent
    }
    console.log(
      "----------------------------sessionaname1",
      selectedRuns.session_name
    );
    const data = { run_id };
    try {
      const response = await EachRunApi(data);
      onManifestUpdate(response.data);
      onFileNameChange(response.file_name, run_id);
    } catch (error) {
      console.error("Error in EachRunApi:", error);
      alert("Failed to process the request. Please try again.");
    }
  };

  const handleCheckboxChange = (run_id, e) => {
    e.stopPropagation();
    const newSelectedRuns = selectedRuns.includes(run_id)
      ? selectedRuns.filter((id) => id !== run_id)
      : [...selectedRuns, run_id];
    setSelectedRuns(newSelectedRuns);
    onRunsSelected(newSelectedRuns);
    setSelectAll(newSelectedRuns.length === filteredRuns.length);
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    if (!selectAll) {
      const allRunIds = filteredRuns.map((run) => run.run_id);
      setSelectedRuns(allRunIds);
      onRunsSelected(allRunIds);
    } else {
      setSelectedRuns([]);
      onRunsSelected([]);
    }
    setSelectAll(!selectAll);
  };

  return (
    <div>
      <div className={styles.select}>
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
        <h6>Select All</h6>
        <select
          onClick={handleDropdownClick}
          value={dateRange}
          onChange={handleDateRangeChange}
          className={styles.dropdown}
        >
          <option value="">History</option>
          <option value="30days">Past 30 Days</option>
          <option value="1month">Past 90 Days</option>
          <option value="custom">Custom Range</option>
        </select>
        <div className={styles.reactdatepciker}>
          {showDatePicker && (
            <DatePicker
              selected={customDateRange.start}
              onChange={handleCustomDateChange}
              startDate={customDateRange.start}
              endDate={customDateRange.end}
              selectsRange
              inline
            />
          )}
        </div>
      </div>
      <div className={styles.mainblock}>
        {filteredRuns.map((run) => (
          <div
            key={run.run_id}
            onClick={() => handleRunClick(run.run_id)}
            className={cx(styles.eachrun, {
              [styles.active]: run.run_id === activeRunId,
            })}
          >
            <div className={styles.w10}>
              <input
                type="checkbox"
                checked={selectedRuns.includes(run.run_id)}
                onChange={(e) => handleCheckboxChange(run.run_id, e)}
              />
            </div>
            <div className={styles.w90}>
              <div className={styles.runvalue}>
                <span className={styles.runSidebar}>
                  Job Name: {run.session_name || "N/A"}
                </span>
              </div>
              <div className={styles.dating}>
                <p className={styles.dateSidebar}>{run.time || ""}</p>
                <p className={styles.dateSidebar}>{run.date || "N/A"}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EachSession;
