import React, { useContext } from "react";
import cx from "classnames";
import styles from "./EachAudio.module.css";
import audioIcon from "../../../assests/images/appstore/audioicon.png";
import { AudioContext } from "./Speechsidebar";

const EachAudio = ({
  data,
  index,
  changeSession,
  setOptionIndex,
  setShowAudioOptions,
  setCurrentSessionId,
  setsessionIdurl,
  fetchSummary,
  fetchTranscription,
}) => {
  const { currentSession, updateOptionsIndex } = useContext(AudioContext);

  async function eachAudioClick() {
    setShowAudioOptions(false);
    setOptionIndex(index); // Update the index to the current component
    changeSession(data?.audio_session);
    setsessionIdurl(data?.session_id);
    setCurrentSessionId(data?.session_id);
    await fetchTranscription(data?.session_id);
    await fetchSummary(data?.session_id); // Fetch transcription on click

    // Fetch transcription on click
  }

  return (
    <div
      onClick={eachAudioClick}
      className={cx(
        currentSession === data?.audio_session && styles.activeAudio,
        styles.eachAudio
      )}
    >
      <div className="flex gap12px alignCenter w90">
        <img className="w18px" src={audioIcon} alt="Audio Icon" />
        <h4 className={styles.audioName}>{data?.audio_session}</h4>
      </div>
    </div>
  );
};

export default EachAudio;
