import React, { useState, useCallback } from "react";
import styles from "./imageediting.module.css";
import tarun from "../../assests/images/Blogposter/tarun.png";
import Cropper from "react-easy-crop";

const ImageEditing = () => {
  const [settings, setSettings] = useState({
    brightness: 100,
    contrast: 100,
    saturation: 100,
    grayscale: 0,
    blur: 0,
    sepia: 0,
  });

  const handleSettingChange = (event) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img
          src={tarun}
          style={{
            filter: `
            brightness(${settings.brightness}%)
            contrast(${settings.contrast}%)
            saturate(${settings.saturation}%)
            grayscale(${settings.grayscale}%)
            blur(${settings.blur}px)
            sepia(${settings.sepia}%)
            hue-rotate(${settings.hueRotate}deg)
            invert(${settings.invert}%)
        `,
            opacity: `${settings.opacity}%`,
          }}
          alt="Editable"
        />
      </div>
      <div className={styles.sliders}>
        <label>
          Brightness:
          <input
            type="range"
            name="brightness"
            min="0"
            max="200"
            value={settings.brightness}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Contrast:
          <input
            type="range"
            name="contrast"
            min="0"
            max="200"
            value={settings.contrast}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Saturation:
          <input
            type="range"
            name="saturation"
            min="0"
            max="200"
            value={settings.saturation}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Grayscale:
          <input
            type="range"
            name="grayscale"
            min="0"
            max="100"
            value={settings.grayscale}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Blur:
          <input
            type="range"
            name="blur"
            min="0"
            max="10"
            value={settings.blur}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Sepia:
          <input
            type="range"
            name="sepia"
            min="0"
            max="100"
            value={settings.sepia}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Hue Rotate:
          <input
            type="range"
            name="hueRotate"
            min="0"
            max="360"
            value={settings.hueRotate}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Invert:
          <input
            type="range"
            name="invert"
            min="0"
            max="100"
            value={settings.invert}
            onChange={handleSettingChange}
          />
        </label>
        <label>
          Opacity:
          <input
            type="range"
            name="opacity"
            min="0"
            max="100"
            value={settings.opacity}
            onChange={handleSettingChange}
          />
        </label>
      </div>
    </div>
  );
};

export default ImageEditing;
