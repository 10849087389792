import React, { useEffect, useState } from "react";
import styles from "../../appstore/Testautomation/Newrun.module.css";
import cx from "classnames";
import TestModel from "./TestModel"; // Import the TestModel component
import test from "../../../assests/images/appstore/test.png";
// import EachPeriod from "../../../components/commonSidebar/EachPeriod";
import del from "../../../assests/images/logo/del.png";

import {
  DeletesessionId,
  PostsessionId,
  TestautomationAPI,
  getSessionsAPI,
  getTestRunsAPI,
} from "../../../commonApi";
import Charging from "../../../Charging";

const Newrun = ({ onSessionSelect }) => {
  const [showTestModel, setShowTestModel] = useState(false);
  const [runHistory, setRunHistory] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    Testhistory();
  }, []);

    useEffect(() => {
   
    if (testDetails.length > 0) {
      setSelectedSession(testDetails[0]);
      handleSessionClick(testDetails[0]);
    }
  }, [testDetails]);
  async function Testhistory() {
    try {
      setOpen(true);
      const session = await getTestRunsAPI();
      setOpen(false);
      console.log("session-----", session);
      if (session.success) {
        setTestDetails(session.session_ids);
        console.log("--", session.session_ids);
      } else {
        setOpen(false);
        // showError(session.message);
      }
    } catch (e) {
      console.log("error---", e);
      // showError(e);
    }
  }

  const handleSessionClick = async (sessionTitle) => {
    try {
      setOpen(true);
      const response = await PostsessionId({ session_id: sessionTitle });
      setOpen(false);
      if (response.success) {
        onSessionSelect(response);
        setSelectedSession(sessionTitle);
        console.log("Data onSelectSession for session:", response);
        // Update the state or handle data as needed
      } else {
        console.error("Failed to fetch data for session:", response.message);
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setOpen(false);
    }
  };

  console.log("testDetails------>", testDetails);

  const handleNewRunClick = async () => {
    setShowTestModel(true);
  };

  const handleCloseTestModel = () => {
    setShowTestModel(false);
    Testhistory(); // Set showTestModel to false to hide the TestModel component
  };

  const handleSessionDelete = async (sessionTitle) => {
    try {
      setOpen(true);
      const response = await DeletesessionId({ session_id: sessionTitle });
      setOpen(false);
      if (response.success) {
        // Remove the deleted session from the testDetails state
        const updatedSessions = testDetails.filter(session => session !== sessionTitle);
        setTestDetails(updatedSessions);
        // Optionally clear the selected session if it was the one deleted
        if (selectedSession === sessionTitle) {
          setSelectedSession(null);
        }
        console.log("Session deleted:", sessionTitle);
      } else {
        console.error("Failed to delete session:", response.message);
      }
    } catch (error) {
      console.error("Error deleting session:", error);
    } finally {
      setOpen(false);
    }
  };
  



  return (
    <div className={styles.commonsidebar}>
      <Charging open={open} />

      <div className={styles.history}>
        <h2>History</h2>
        <div className={styles.full}>
          <div className={styles.search}>
            <div className={cx("commonbtn")} onClick={handleNewRunClick}>
              <i className="fa fa-plus"></i>
              <span>New Run</span>
            </div>
            <div className={cx("posrel")}>
              <input
                type="text"
                autoComplete="off"
                placeholder="Search.."
                className={styles.input}
              />
              <i
                className={cx(
                  "fa fa-solid fa-magnifying-glass",
                  styles.searchabs
                )}
              ></i>
            </div>
          </div>
        </div>
        {showTestModel && <TestModel closeTest={handleCloseTestModel} />}
      </div>
      <div className={styles.runs}>
        {testDetails.map((run, idx) => (
          <div
            className={cx(styles.test, {
              [styles.selected]: selectedSession === run,
            })}
            onClick={() => handleSessionClick(run)}
          >
            <img src={test} />
            <p className={styles.run}>{run}</p>
            <div className={styles.del}>
            <img src={del} className={styles.del}  onClick={() => handleSessionDelete(run)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Newrun;
