import CommonTitleBar from "../CommonTitleBar";
import styles from "../user.module.css";
import plus from "../../../assests/images/logo/plus.png";
import pencil from "../../../assests/images/logo/pencil.png";
import deleteIcon from "../../../assests/images/logo/deleteIcon.png";
import { Link } from "react-router-dom";
import { list_roleUsers } from "../../../commonApi";
import React, { useEffect, useRef, useState } from "react";

const RoleManagement = () => {
  const [open, setOpen] = useState(false);
  const [roleData, setroleData] = useState([]);

  useEffect(() => {
    getroleDetails();
  }, []);

  async function getroleDetails() {
    try {
      setOpen(true);
      const roleManage = await list_roleUsers();
      setOpen(false);
      console.log("roleManage-----", roleManage);
      if (roleManage.success) {
        setroleData(roleManage.data);
      } else {
        setOpen(false);
        // showError(userManage.message);
      }
    } catch (e) {
      console.log("error---", e);
      // showError(e);
    }
  }

  return (
    <div>
      <CommonTitleBar />
      <div>
        <CommonTitleBar />
        <div className={styles.inneruser}>
          <div className={styles.flex}>
            <Link to="/roleManagement/rolecreate">
              <div className={styles.commonbtn}>
                <img src={plus} alt="" /> <span>Create New Role</span>
              </div>
            </Link>
            <div className={styles.commonbtn}>
              <img src={pencil} alt="" /> <span>Edit</span>
            </div>
            <div className={styles.commonbtn}>
              <img src={deleteIcon} alt="" /> <span>Delete</span>
            </div>
          </div>
        </div>
        <div className={styles.usertable}>
          <table>
            <thead>
              <tr>
                <th className={styles.padleft40}>User Roles</th>
                <th>Policies</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {roleData?.map((item, idx) => {
                return (
                  <tr>
                    <td>
                      <div className={styles.d_table}>
                        <input
                          autoComplete="off"
                          type="checkbox"
                          value={item.role_id}
                          className={styles.checkboxforrole}
                        ></input>
                        <label for>{item.role_name} </label>
                      </div>
                    </td>
                    <td>
                      {/* <div className={styles.details}>
                        <div>
                          <h2>1.user_management</h2>
                          <ul>
                            <li>Update the profile info of any user</li>
                          </ul>
                        </div>
                      </div> */}
                      {item?.smart_chat === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> Smart Chat </h2>
                            {/* <ul>
                            <li>Access to Smart Chat</li>
                          </ul> */}
                          </div>
                        </div>
                      )}

                      {item?.data_dive === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> Data Dive </h2>
                            {/* <ul>
                            <li>Access to Data Dive </li>
                          </ul> */}
                          </div>
                        </div>
                      )}
                      {/* <div className={styles.details}>
                        <div>
                          <h2> 4.App Hub </h2>
                          <ul>
                            <li>Access to App Hub</li>
                          </ul>
                        </div>
                      </div> */}
                       {item?.model_opt === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> Model Opt </h2>
                            {/* <ul>
                            <li>Access to Data Dive </li>
                          </ul> */}
                          </div>
                        </div>
                      )}
                     {item?.model_fine_tuning === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> Model Fine Tuning </h2>
                            {/* <ul>
                            <li>Access to Data Dive </li>
                          </ul> */}
                          </div>
                        </div>
                      )}
                     {item?.hub_plus === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> Hub Plus </h2>
                            {/* <ul>
                            <li>Access to Data Dive </li>
                          </ul> */}
                          </div>
                        </div>
                      )}
                       {item?.app_design === "1" && (
                        <div className={styles.details}>
                          <div>
                            <h2> App Design </h2>
                            {/* <ul>
                            <li>Access to Data Dive </li>
                          </ul> */}
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className={styles.d_table}></div>
                    </td>
                    <td>
                      <div className={styles.d_table}>{item.created_at}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
