// import React from "react";
import CommonTitleBar from "../management/CommonTitleBar";
import { Link, useNavigate } from "react-router-dom";
import styles from "../management/user.module.css";
import plus from "../../assests/images/logo/plus.png";
import { FormControl, Select } from "@mui/material";
import cx from "classnames";
import axios from "axios";
import { Toast } from "primereact/toast";

import Charging from "../../Charging";

import { create_user } from "../../commonApi";
import React, { useEffect, useRef, useState } from "react";
// import cx from "classnames";
// import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const businessunit = [
  "business unit 1",
  "business unit 2",
  "business unit 3",
  "business unit 4",
  "business unit 5",
];

// const assignrole = [
//   "assign role 1",
//   "assign role  2",
//   "assign role 3",
//   "assign role 4",
//   "assign role 5",
// ];

export const Usercreate = () => {
  const [createuser, setCreateUser] = useState([]);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [businessUnit, setbusinessUnit] = useState([]);
  const [assignRole, setassignRole] = useState([]);

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    is_active: "1",
    assign_role: "",
    is_superuser: "1",
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setbusinessUnit(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // const handleChange1 = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setassignRole(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  function changeHandler(e) {
    const {
      target: { name, value },
    } = e;
    // let name = e.target.name;
    // let value = e.target.value;
    if (name === "user_mobile") {
      console.log("value-----", value, value.length);
      if (value.length <= 10) {
        console.log("iuytfdfghj");
        setUser({ ...user, [name]: value });
      }
    } else {
      console.log("e--", e.target, name, value);
      setUser({ ...user, [name]: value });
    }
  }

  console.log("user------", user);

  async function create_usermanagement() {
    try {
      setOpen(true);
      const createuser = await create_user(user);

      setOpen(false);
      if (createuser.success) {
        navigate("/userManagement");
      } else {
        setOpen(false);
        showError(createuser.message);
      }
    } catch (e) {
      showError("There is an Error--", e);
    }
  }

  async function submitForm(e) {
    try {
      e.preventDefault();
      if (
        user.email.includes("gmail") ||
        user.email.includes("yahoo") ||
        user.email.includes("outlook") ||
        user.email.includes("protonmail") ||
        user.email.includes("zoho") ||
        user.email.includes("icloud")
      ) {
        console.log("only orgnaisation mail id should be there");
        showError("Use Only Orgnaisation mail_id");
        setUser({ ...user, email: "" });
      } else if (user.user_mobile.length !== 10) {
        showError("Enter Correct Mobile Number");
      } else {
        create_usermanagement();
      }
    } catch (e) {
      console.log("There is an Error--", e);
    }
  }

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />

      <CommonTitleBar />

      <form onSubmit={submitForm}>
        <div className={styles.inside_newuser}>
          <div className={styles.width50}>
            <div className={styles.d_flex_gap30}>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  First Name
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  required
                  className={styles.inputcommontextforgot}
                  name="user_firstname"
                  onChange={changeHandler}
                  id=""
                />
              </div>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Last Name
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  required
                  className={styles.inputcommontextforgot}
                  name="user_last_name"
                  id=""
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className={styles.d_flex_gap30}>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Email ID
                </label>
                <input
                  autoComplete="off"
                  type="email"
                  required
                  className={styles.inputcommontextforgot}
                  name="email"
                  id=""
                  onChange={changeHandler}
                />
              </div>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Phone Number
                </label>
                <input
                  className={styles.inputcommontextforgot}
                  pattern="[0-9]{10}"
                  placeholder="Mobile No."
                  value={user.user_mobile}
                  name="user_mobile"
                  // onChange={
                  //   user?.user_mobile?.length <= 10 ? changeHandler : ""
                  // }
                  onChange={changeHandler}
                  type="number"
                  size="10"
                  required
                  autoComplete="off"
                />
                {/* <input
                  type="number"
                  required
                  className={styles.inputcommontextforgot}
                  pattern="[0-9]{10}"
                  placeholder="Mobile No."
                  value={user?.user_mobile}
                  name="user_mobile"
                  onChange={
                    user?.user_mobile?.length <= 10 ? changeHandler : ""
                  }
                  id=""
                /> */}
              </div>
            </div>

            <div className={styles.d_flex_gap30}>
              {/* <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Department
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  required
                  className={styles.inputcommontextforgot}
                  name="department"
                  onChange={changeHandler}
                  id=""
                />
              </div> */}
            </div>
            <div className={styles.d_flex_gap30}>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Password
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  onChange={changeHandler}
                  required
                  className={styles.inputcommontextforgot}
                  name="password"
                  //   onChange={newUserChanging}
                  id=""
                />
              </div>
              <div className={styles.each_for_box}>
                <label htmlFor="" className={styles.labelforgot}>
                  Confirm Password
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  onChange={changeHandler}
                  required
                  className={styles.inputcommontextforgot}
                  name="confirm_password"
                  id=""
                />
              </div>
            </div>

            <div className={styles.d_flex_gap30}>
              <div className={styles.each_for_box}>
                <label className={styles.labelforgot}>Business unit</label>
                <FormControl>
                  <InputLabel id="demo-multiple-checkbox-label"></InputLabel>

                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox tarun"
                    multiple
                    value={businessUnit}
                    onChange={handleChange}
                    className={styles.business1}
                    input={<OutlinedInput label="" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {businessunit.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={businessUnit.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.each_boxing}>
                <Link to="/businessManagement">
                  <div className={cx(styles.eachbutton, styles.buttonnewrole)}>
                    <img src={plus} alt="" />{" "}
                    <span className={styles.new}>New Business Unit</span>
                  </div>
                </Link>
              </div>
            </div>

            <div className={styles.d_flex_gap30}>
              <div className={styles.each_for_box}>
                <label className={styles.labelforgot}>Assign User Role</label>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={userEditdata?.assign_role}
                    label="Select your Project"
                    // onChange={userEditChange}
                    className={styles.business1}
                    name="assign_role"
                    required
                  >
                    <MenuItem>Role 1</MenuItem>
                    <MenuItem>Role 2</MenuItem>
                    <MenuItem>Role 3</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={styles.each_boxing}>
                <Link to="/roleManagement">
                  <div className={cx(styles.eachbutton, styles.buttonnewrole)}>
                    <img src={plus} alt="" />{" "}
                    <span className={styles.new}>New User Role</span>
                  </div>
                </Link>
              </div>
            </div>
            <button type="submit" className={styles.commonbtn}>
              Create User
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
