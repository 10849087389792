import { useState, useEffect, useRef } from "react";
import React from "react";
import styles from "../SpeechToText/speechtotext.module.css";
import Speechsidebar from "./Speechsidebar";
import cx from "classnames";
import audio from "../../../assests/images/appstore/Audio.png";
import uploadAudio from "../../../assests/images/appstore/UploadAudio.png";
import Link from "../../../assests/images/appstore/link.png";
import SubmitArrow from "../../../components/SubmitArrow";
import downld from "../../../assests/images/texttoimage/dwnld.svg";
import roboIcon from "../../../assests/images/normalchat/roboIcon.png";
import Popforlink from "./Popforlink";
import {
  CreateSession,
  DocQueryApi,
  doEMbeddings,
  getDocQuery,
  GetSessionsAudio,
  GetSummary,
  Gettranscription,
  PostRecord,
  PostTranscription,
  SpeechSummaryApi,
  SpeechSummaryputApi,
  UploadFile,
} from "../../../commonApi";
import Charging from "../../../Charging";
import { Toast } from "primereact/toast";

import { v4 as uuidv4 } from "uuid";

const SpeechToText = () => {
  const [activeState, setActiveState] = useState("");
  const [activeStaterecord, setActiveStaterecord] = useState("");
  const [filename, setFilename] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const [showFilenameModal, setShowFilenameModal] = useState(false);
  const [showAudioOptions, setShowAudioOptions] = useState(true);
  const [query, setQuery] = useState("");
  const fileInputRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [pathurl, setPathurl] = useState("");
  const [sessionIdurl, setsessionIdurl] = useState("");
  const [sessionIeachid, setsessioneachID] = useState("");
  const [micColor, setMicColor] = useState("#B1B1B1");
  const [transcriptPage, settranscriptionPage] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryeach, setSummaryEach] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [instruction, setInstructions] = useState("");

  const [docquery, setDocquery] = useState("");
  const [messages, setMessages] = useState([]);
  const [TranscriptionVisible, setTranscriptionVisible] = useState(true);
  const [summaryVisible, setsummaryVisible] = useState(true);
  const [currentSessionId, setCurrentSessionId] = useState("");
  const [pronoun, setPronoun] = useState("He");

  const [reload, setReload] = useState(false);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const mediaRecorderRef = useRef(null);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [audioType, setAudioType] = useState("");

  const getUniqueFilename = () => {
    return `recording_${uuidv4()}.mp3`; // UUID is highly unlikely to be duplicated
  };

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });

        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log(`Chunk received: ${event.data.size} bytes`);
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstart = () => {
          console.log("MediaRecorder started");
        };

        mediaRecorderRef.current.onerror = (event) => {
          console.error("MediaRecorder error:", event.error);
        };

        mediaRecorderRef.current.start(1000); // Collect data every second
        console.log("Recording started");

        setupAudioVisualization(stream);
        setIsRecording(true);
      } catch (err) {
        console.error("Error accessing the microphone:", err);
      }
    } else {
      console.error("getUserMedia not supported on your browser!");
      alert(
        "Audio recording is not supported in your browser. Please switch to a modern browser or check your browser settings."
      );
    }
  };

  // const stopRecording = () => {

  //   mediaRecorderRef.current.stop();
  //   mediaRecorderRef.current.stream
  //     .getTracks()
  //     .forEach((track) => track.stop());
  //   window.cancelAnimationFrame(animationFrameRef.current);
  //   const audioBlob = new Blob(audioChunksRef.current, {
  //     type: mediaRecorderRef.current.mimeType,
  //   });
  //   const audioUrl = URL.createObjectURL(audioBlob);
  //   setAudioURL(audioUrl);
  //   setIsRecording(false);
  //   setIsPaused(false);
  //   setAudioType(mediaRecorderRef.current.mimeType);
  //   audioChunksRef.current = [];
  //   setMicColor("#B1B1B1"); // Reset the microphone color to its initial state
  // };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      console.log("MediaRecorder stopped");

      mediaRecorderRef.current.onstop = () => {
        console.log(
          `Recording stopped. Total chunks: ${audioChunksRef.current.length}`
        );

        if (audioChunksRef.current.length === 0) {
          console.error("Error: No audio chunks recorded");
          return;
        }

        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        console.log(`Final Audio Blob size: ${blob.size} bytes`);

        if (blob.size === 0) {
          console.error("Error: Audio blob is empty");
          return;
        }

        setAudioBlob(blob);
        setShowFilenameModal(true);
      };

      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      window.cancelAnimationFrame(animationFrameRef.current);

      setIsRecording(false);
      setIsPaused(false);
      setMicColor("#B1B1B1");
    } else {
      console.error("MediaRecorder is not in a state to be stopped");
    }
  };

  const handleFilenameSubmit = () => {
    if (!filename.trim()) {
      alert("Please enter a filename");
      return;
    }

    const formData = new FormData();
    formData.append("file", audioBlob, `${filename}.mp3`); // Ensure the file extension matches your actual recording format

    for (let pair of formData.entries()) {
      console.log(`FormData entry - ${pair[0]}: ${pair[1]}`);
      if (pair[1] instanceof Blob) {
        console.log(
          `Blob in FormData: ${pair[1].size} bytes, type: ${pair[1].type}`
        );
      }
    }

    // Send the FormData

    setOpen(true);

    // Send the FormData
    PostRecord(formData)
      .then((response) => {
        console.log("Response from server:", response);
        if (response.success && response.path) {
          // Assuming 'link' is part of the response where the audio file is accessible
          createSession(response.fileName, response.path);
        } else {
          throw new Error("Failed to upload file or no link provided");
        }
      })
      .catch((error) => {
        console.error("Error posting recording:", error);
      })
      .finally(() => {
        // Indicate loading or processing end
        setOpen(false);
      });

    // Reset states
    setShowFilenameModal(false);
    setFilename("");
  };

  const setupAudioVisualization = (stream) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
    const source = audioContextRef.current.createMediaStreamSource(stream);
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 256;
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    source.connect(analyserRef.current);

    const draw = () => {
      animationFrameRef.current = requestAnimationFrame(draw);
      analyserRef.current.getByteFrequencyData(dataArray);
      let sum = dataArray.reduce((a, b) => a + b, 0);
      let average = sum / bufferLength;
      let volume = average / 128.0;
      // Adjust the color based on volume. It becomes more intense as volume increases.
      const intensity = Math.min(volume, 1); // Normalize intensity to a max of 1
      const newColor = `rgba(14, 142, 190, ${intensity})`; // Dynamically adjust transparency based on volume
      setMicColor(newColor);
    };
    draw();
  };
  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };
  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
      setActiveStaterecord(""); // Clear active state when stopping
    } else {
      startRecording();
      setActiveStaterecord("record"); // Set active state to 'record' when starting
    }
  };

  const handleRecordClick = () => {
    setIsRecording(!isRecording); // Toggle recording state
    // Add actual recording logic here
  };

  const handlePronounChange = (e) => {
    setPronoun(e.target.value);
  };

  const handleExtractedFilename = (filename, link) => {
    setSessionName(filename);
    setLinkUrl(link); // Store the link in state
    createSession(filename, link); // Pass link to createSession
    closeLinkModal();
  };
  useEffect(() => {
    if (currentSessionId) {
      fetchDocquery(currentSessionId);
    }
  }, [currentSessionId]);

  useEffect(() => {
    console.log("-----------audiourol", audioURL);
  });

  useEffect(() => {
    if (transcriptPage) {
      // setActiveState("Transcription");
      setTranscriptionVisible(true);
    }
  }, [transcriptPage]);

  useEffect(() => {
    if (summary) {
      // setActiveState("Summary");

      setsummaryVisible(true);
    }
  }, [summary]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const submitsummary = () => {
    setActiveState("Summary");
  };

  const Docquery = () => {
    setActiveState("Doc_Query");
    fetchDocquery(currentSessionId); // Use the propagated currentSessionId here
  };
  const handleInstructions = (e) => {
    setInstructions(e.target.value);
  };

  const handleSummaryClick = () => {
    if (sessionIdurl && transcriptPage) {
      // Make sure there is a session ID and content available
      console.log("------------------------------whats hapeenig");
      PutSummary(sessionIdurl, transcriptPage);
      setInstructions("");
    } else {
      console.error("Missing session ID or transcription content");
      // Optionally, show an error message to the user
    }
  };
  const createSession = async (sessionName, link) => {
    try {
      setOpen(true);
      const sessionResponse = await CreateSession({ sessionName });
      if (sessionResponse.success) {
        console.log("Session created with ID:", sessionResponse.session_id);
        setReload(true);
        await Posttranscription(sessionResponse.session_id, link); // Use the link here
      } else {
        throw new Error(
          "Error in creating session: " + sessionResponse.message
        );
      }
    } catch (error) {
      console.error("Session creation failed:", error);
      setUploadStatus(`Error in creating session: ${error.message}`);
    } finally {
      setOpen(false);
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files[0];
    if (files) {
      console.log("File selected:", files.name);
      const formData = new FormData();
      formData.append("files", files);
      try {
        setOpen(true);

        const uploadResponse = await UploadFile(formData);
        if (uploadResponse.success) {
          setPathurl(uploadResponse.path);
          setUploadStatus("Audio file uploaded successfully.");

          // setPathurl(uploadResponse.path);

          console.log("----------------->patyh", pathurl);
          const sessionResponse = await CreateSession({
            sessionName: files.name,
          });
          if (sessionResponse.success) {
            setsessioneachID(sessionResponse.session_id);
            console.log(
              "-------------------------------------------------  sessionResponse.session_id,",
              sessionIeachid
            );
            setOpen(false);
            setReload(true);
            await Posttranscription(
              sessionResponse.session_id,
              uploadResponse.path
            );

            console.log(
              "----------------------------sessionIdurl",
              sessionResponse.session_id
            );
          } else {
            console.error(
              "Error in creating session:",
              sessionResponse.message
            );
            setUploadStatus(
              "Error in creating session: " + sessionResponse.message
            );
          }
        } else {
          setUploadStatus("Error in uploading file: " + uploadResponse.message);
        }
      } catch (error) {
        setUploadStatus("Error in uploading file: " + error.message);
        console.error("Upload or session creation failed:", error);
      }
    }
  };

  async function Posttranscription(sessionId, fileUrl) {
    try {
      const transcription = {
        sessionId,
        fileUrl,
      };
      setOpen(true);
      const response = await PostTranscription(transcription);
      if (response.success) {
        settranscriptionPage(response.content); // Set the transcription page content first
        console.log("Transcription Success:", response);
        // Set active state and make transcription visible in a synchronous manner
        setActiveState("Transcription"); // This will switch to the Transcription tab
        // setTranscriptionVisible(true);
        setShowAudioOptions(false);
        setOpen(false);

        await PostSummary(sessionId, response.content);
        doEMbeddings(sessionId);
        // Ensure the transcription is visible
      } else {
        console.log("Transcription Failed:", response.message);
      }
    } catch (error) {
      console.error("Error during transcription posting:", error);
    }
  }

  async function PostSummary(sessionId, content) {
    try {
      const summary = {
        sessionId: sessionId,
        pronoun: pronoun,
        instructions: instruction,
        content: content,
      };
      // setOpen(true);
      const response = await SpeechSummaryApi(summary);
      if (response.success) {
        setSummary(response.content); // Set the transcription page content first
        console.log("summary Success:", response);
        // setOpen(false);
        // Set active state and make transcription visible in a synchronous manner
        // setActiveState("Summary"); // This will switch to the Transcription tab
        // setsummaryVisible(true);
        setShowAudioOptions(false); // Ensure the transcription is visible
      } else {
        console.log("Transcription Failed:", response.message);
      }
    } catch (error) {
      console.error("Error during transcription posting:", error);
    }
  }

  const DocQuery = async (sessionId, query) => {
    try {
      // Prepare the data payload
      const docQueryData = {
        question: query,
      };

      // Show loading or processing indicator
      setOpen(true);

      // Call the DocQuery API with the session ID and the question data
      const response = await DocQueryApi(sessionId, docQueryData);

      fetchDocquery(sessionId);

      // if (response.success) {
      // Update the state with the response content
      // setDocquery(response.content);
      // console.log("DocQuery Success:", response);

      // Update UI state to show the DocQuery tab and content
      // setActiveState("Doc_Query");
      // setShowAudioOptions(false);
      // } else {
      //   console.log("DocQuery Failed:", response.message);
      //   // Handle error appropriately
      //   showError(response.message);
      // }

      // Hide loading or processing indicator
      setOpen(false);
    } catch (error) {
      console.error("Error during doc query posting:", error);
      // Handle error appropriately
      showError(error.message);
    }
  };

  async function PutSummary(sessionId, content) {
    try {
      const summaryData = {
        sessionId,
        pronoun,
        instructions: instruction,
        content,
      };
      setOpen(true);
      const response = await SpeechSummaryputApi(summaryData);
      if (response.success) {
        setSummary(response.content); // Set the transcription page content first
        console.log("summary Success:", response);
        fetchSummary(sessionId);
        setOpen(false);
        // Set active state and make transcription visible in a synchronous manner
        // setActiveState("Summary"); // This will switch to the Transcription tab
        // setsummaryVisible(true);
        setShowAudioOptions(false); // Ensure the transcription is visible
      } else {
        console.log("Transcription Failed:", response.message);
      }
    } catch (error) {
      console.error("Error during transcription posting:", error);
    }
  }

  const fetchSummary = async (session_id) => {
    try {
      setOpen(true);

      const summary = await GetSummary(session_id);
      setOpen(false);

      console.log("----------------------->summary", summary.data.summery);
      if (summary.success) {
        setSummary(summary.data.summery);
        console.log("-----------------------", summary.data);
        // setsummaryVisible(true);
        // setActiveState("Summary");

        // toast.current.show({
        //   severity: "success",
        //   summary: "Transcription Retrieved",
        //   detail: "Successfully retrieved transcription.",
        // });
      } else {
        showError(summary.message);
      }
    } catch (error) {
      // showError(error.message);
    }
  };

  const fetchDocquery = async (session_id) => {
    try {
      const response = await getDocQuery(session_id);
      if (response.success) {
        setMessages(response.data);

        console.log("--------------------------messages", messages[0]); // Assuming 'data' is the array of messages
      } else {
        console.error("Failed to fetch messages:", response.message);
      }
    } catch (error) {
      console.error("Error fetching messages:", error.message);
    }
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const query = e.target.searching.value; // Get the query from the form input
    if (query) {
      // Call the DocQuery function with the current session ID and the query
      await DocQuery(sessionIdurl, query);
      setQuery("");
    }
  };

  const handleLinkClick = () => {
    setShowLinkModal(true);
  };

  const closeLinkModal = () => {
    setShowLinkModal(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.head}>
        <div className={styles.left}>
          <Speechsidebar
            setShowAudioOptions={setShowAudioOptions}
            reload={reload}
            setReload={setReload}
            setsessionIdurl={setsessionIdurl}
            settranscriptionPage={settranscriptionPage}
            setSummary={setSummary}
            setTranscriptionVisible={setTranscriptionVisible}
            setsummaryVisible={setsummaryVisible}
            setActiveState={setActiveState}
            onSessionChange={setCurrentSessionId}
          />
        </div>

        <div className={styles.fixed}>
          {showAudioOptions ? (
            <div className={styles.sep}>
              <div className={styles.header}>New Audio</div>

              <div className={styles.audioOptions}>
                <div
                  className={`${styles.audioOptionBox} ${
                    activeStaterecord === "record"
                      ? styles.active
                      : activeStaterecord
                      ? styles.disabled
                      : ""
                  }`}
                  onClick={toggleRecording}
                >
                  <svg
                    className={styles.recordaudio}
                    viewBox="0 0 85 124"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M42.5 77.5C54.5932 77.5 64.3939 67.0929 64.3939 54.25V23.25C64.3939 10.4071 54.5932 0 42.5 0C30.4068 0 20.6061 10.4071 20.6061 23.25V54.25C20.6061 67.0929 30.4068 77.5 42.5 77.5ZM85 53.9732C85 53.3643 84.5364 52.8661 83.9697 52.8661H76.2424C75.6758 52.8661 75.2121 53.3643 75.2121 53.9732C75.2121 73.3897 60.5689 89.125 42.5 89.125C24.4311 89.125 9.78788 73.3897 9.78788 53.9732C9.78788 53.3643 9.32424 52.8661 8.75758 52.8661H1.0303C0.463636 52.8661 0 53.3643 0 53.9732C0 77.3201 16.3045 96.5844 37.3485 99.3107V113.482H18.6356C16.8712 113.482 15.4545 115.461 15.4545 117.911V122.893C15.4545 123.502 15.8152 124 16.253 124H68.747C69.1848 124 69.5455 123.502 69.5455 122.893V117.911C69.5455 115.461 68.1288 113.482 66.3644 113.482H47.1364V99.3799C68.425 96.8888 85 77.5138 85 53.9732Z"
                      fill={micColor}
                    />
                    {isPaused && (
                      <line
                        x1="20"
                        y1="20"
                        x2="65"
                        y2="65"
                        stroke="white"
                        strokeWidth="8"
                        strokeLinecap="round"
                      />
                    )}
                  </svg>
                  <div className={styles.recordLabelContainer}>
                    {isRecording ? (
                      <>
                        <div className={styles.blinkingDot}></div>
                        <span className={styles.recordLabel}>
                          <h6>{isPaused ? "Paused" : "Stop Recording"}</h6>
                        </span>
                      </>
                    ) : (
                      <h6 className={styles.recordLabel}>Start Recording</h6>
                    )}
                  </div>
                </div>

                {showFilenameModal && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h5>Enter Filename</h5>
                      <input
                        type="text"
                        value={filename}
                        onChange={(e) => setFilename(e.target.value)}
                        placeholder="Enter filename "
                      />
                      <div className={styles.save}>
                        <button
                          className="commonbtn"
                          onClick={handleFilenameSubmit}
                        >
                          Save
                        </button>
                        <button
                          className={styles.cancel}
                          onClick={() => setShowFilenameModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`${styles.audioOptionBox} ${
                    activeStaterecord === "upload"
                      ? styles.active
                      : activeStaterecord
                      ? styles.disabled
                      : ""
                  }`}
                  onClick={handleUploadClick}
                >
                  <img src={uploadAudio} alt="Upload" />
                  <h6> Upload</h6>
                  <input
                    type="file"
                    accept=".mp3"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div
                  className={`${styles.audioOptionBox} ${
                    activeStaterecord === "link"
                      ? styles.active
                      : activeStaterecord
                      ? styles.disabled
                      : ""
                  }`}
                  onClick={handleLinkClick}
                >
                  <img src={Link} /> <h6>Link</h6>
                </div>
                {showLinkModal && (
                  <Popforlink
                    closeModel={closeLinkModal}
                    onExtract={handleExtractedFilename}
                  />
                )}
              </div>
              {/* {uploadStatus && ( // Display the upload status message if available
                <div className={styles.uploadStatus}>{uploadStatus}</div>
              )} */}
              <div className={styles.comnbtn}>
                {isRecording && !isPaused ? (
                  <button className="commonbtn" onClick={pauseRecording}>
                    Pause
                  </button>
                ) : isRecording && isPaused ? (
                  <button className="commonbtn" onClick={resumeRecording}>
                    Resume
                  </button>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <div className={cx(styles.dflex, "justifyBetween", styles.new)}>
                <div className={styles.gap10}>
                  <h5
                    className={
                      activeState === "Transcription"
                        ? styles.currentButton
                        : ""
                    }
                    onClick={() => setActiveState("Transcription")}
                  >
                    Transcription
                  </h5>
                  <h5
                    className={
                      activeState === "Summary" ? styles.currentButton : ""
                    }
                    onClick={() => submitsummary()}
                  >
                    Summary
                  </h5>
                  <h5
                    className={
                      activeState === "Doc_Query" ? styles.currentButton : ""
                    }
                    onClick={() => Docquery()}
                  >
                    Doc Query
                  </h5>
                </div>
              </div>

              {TranscriptionVisible && activeState === "Transcription" && (
                <div className={styles.transcriptionContainer}>
                  <div className={styles.transcriptionPage}>
                    <p>{transcriptPage}</p>
                  </div>
                </div>
              )}

              {activeState === "Summary" && (
                <div className={styles.Summarycontainer}>
                  <div className={styles.Summaryleft}>
                    <div className={styles.leftContent}>
                      <p>
                        {summary.split("\n").map((line, index) => {
                          // Check if the line ends with a colon to assume it's a heading
                          const isHeading = line.trim().endsWith(":");
                          return (
                            <React.Fragment key={index}>
                              {isHeading ? <strong>{line}</strong> : line}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                  <div className={styles.Summaryright}>
                    <h6>Pronoun</h6>
                    <select
                      className={styles.pronounDropdown}
                      value={pronoun}
                      onChange={handlePronounChange}
                    >
                      <option value="he">He</option>
                      <option value="she">She</option>
                    </select>

                    <h6>Instructions</h6>
                    <div className={styles.instructions}>
                      <textarea
                        className={styles.inp}
                        type="text"
                        value={instruction}
                        onChange={handleInstructions}
                      />
                      {/* <p>
                        Please select the appropriate pronoun based on the
                        context of the text you are analyzing. For example, use
                        "He" when referring to a male subject and "She" when
                        referring to a female subject.
                      </p> */}
                    </div>
                    <div className={styles.regen}>
                      <button
                        onClick={handleSummaryClick}
                        className="commonbtn"
                      >
                        Regenerate
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {activeState === "Doc_Query" && (
                <div>
                  {messages.map((message, index) => {
                    if (message.user) {
                      return (
                        <div key={index} className={styles.chat}>
                          <div className={cx(styles.human)}>
                            <div className="flex w70 gap6px">
                              <div className="w8"></div>
                              <div className={styles.content}>
                                <div className="justifyEnd">{message.user}</div>
                                <div className={styles.time}></div>
                              </div>
                              <div className="w8">
                                <p> MP </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (message.bot) {
                      // Split the message on every new line and map it to include <br /> tags
                      const messageLines = message.bot
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));

                      return (
                        <div key={index} className={cx(styles.robot)}>
                          <div className="flex w70 gap6px">
                            <div className="w8">
                              <img src={roboIcon} alt="" />
                            </div>
                            <div className={styles.content}>
                              <div className="justifyStart colDir">
                                {messageLines}
                              </div>
                              <div className={styles.time}>
                                {new Date().toLocaleString()}
                              </div>
                            </div>
                            <div className="w8"></div>
                          </div>
                        </div>
                      );
                    }
                  })}
                  <div className={cx(styles.serching1, "flexColCenter")}>
                    <form
                      className={styles.formChatData}
                      onSubmit={handleSubmit}
                    >
                      <div className={styles.inpEnter}>
                        <input
                          type="text"
                          name="searching"
                          onChange={(e) => setQuery(e.target.value)}
                          autoComplete="off"
                          placeholder="Enter your query..."
                          value={query}
                        />
                        <button
                          type="submit"
                          disabled={query.length > 0 ? false : true}
                        >
                          <SubmitArrow query={query} />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpeechToText;
