import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";

export function scrollToView(data) {
    console.log('data------->', data);
    setTimeout(() => {
        let s1 = document.getElementById(data);
        if (s1) {
            console.log('showingggggggg')
            s1.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }, 100)
}

export function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
        for (let i = 0; i < eachCheck.length; i++) {
            eachCheck[i].checked = true;
        }
    } else {
        for (let i = 0; i < eachCheck.length; i++) {
            eachCheck[i].checked = false;
        }
    }
}

export function checkBoxEach(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
        if (!i.checked) {
            checkboxparent.checked = false;
        }
    }
}

export function uncheckAll() {
    const checkboxAll = document.querySelectorAll("[type=checkbox]");
    // console.log("checkboxAll-------", checkboxAll);
    for (let i of checkboxAll) {
        console.log(i);
        if (i.checked) {
            // console.log("checked already");
            i.checked = false;
        }
    }
}

export function getUserId() {
    const cookies = new Cookies();
    const checkLogin = cookies.get("genAi");
    if (checkLogin) {
        let t1 = jwtDecode(checkLogin);
        return t1["user_account_id"]
    }
    return false
}

export function toggle() {
    const toggleDropdown = document.getElementsByClassName("dropdown-menu");
    for (let i of toggleDropdown) {
        i.classList.remove("show");
    }
}

