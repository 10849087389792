import React, { useEffect, useState } from "react";
import styles from "../projects/projectsoverview.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import projectImg from "../../../assests/images/datavizard/datasources.png";
import datasourceImg from "../../../assests/images/datavizard/datasources.png";
import graphIcon from "../../../assests/images/datavizard/graphIcon.png";
import tableIcon from "../../../assests/images/datavizard/tableIcon.png";
import playBtn from "../../../assests/images/logo/playBtn.png";
import PlotGraph from "../../../GraphDatabase/PlotGraph";
import {
  generateGraphAPI,
  generateSQLAPI,
  generateTableAPI,
  updateChartAPI,
} from "../../../commonApi";
import { updatingLoading } from "../../../store/slice/loadingAndError";
import { useDispatch } from "react-redux";

const ChartAndTables = () => {
  const [question, setQuestion] = useState("");
  const [ques, setQues] = useState("");
  const [graph, setGraph] = useState({});
  const [sql, setSql] = useState("");
  const [tableData, setTableData] = useState({});
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { data } = state;
  const [project_id, setProject_id] = useState();
  const { projectName, datasource_id } = useParams();
  const [tableOrGraph, setTableOrGraph] = useState("Graph")
  const navigate = useNavigate();

  console.log(data, datasource_id);

  useEffect(() => {
    setSql(data?.sql_query);
    let d1 = JSON.parse(data?.chart);
    setGraph(d1);
    setQuestion(data?.question);
    setProject_id(data?.project_id);
  }, []);

  const generateSql = async () => {
    try {
      dispatch(updatingLoading(true));
      const askQuestion = await generateSQLAPI(datasource_id, {
        question: ques,
      });
      dispatch(updatingLoading(false));
      console.log(`askQuestion--${askQuestion}`);
      if (askQuestion?.status) {
        executeSql(askQuestion?.sql);
        setSql(askQuestion?.sql);
      }
    } catch (error) {
      console.error("Error posting question:", error);
      dispatch(updatingLoading(false));
    }
  };

  const executeSql = async (data, nograph) => {
    try {
      dispatch(updatingLoading(true));
      let body = {
        query: data,
      };
      const generateTable = await generateTableAPI(datasource_id, body);
      dispatch(updatingLoading(false));
      console.log(`generateTable--${generateTable}`);
      if (generateTable?.status) {
        if (!nograph) {
          generateGraphFun(data);
        }
        setTableData(JSON.parse(generateTable?.data));
      }
    } catch (error) {
      console.error("Error posting question:", error);
      dispatch(updatingLoading(false));
    }
  };

  const generateGraphFun = async (d1) => {
    try {
      dispatch(updatingLoading(true));
      let body = {
        sql: d1,
        question: ques,
      };
      const generateGraph = await generateGraphAPI(datasource_id, body);
      dispatch(updatingLoading(false));
      console.log(`generateGraph--${generateGraph}`);
      if (generateGraph?.status) {
        // createChartFunction(d1, generateGraph.data);
        let d1 = JSON.parse(generateGraph.data);
        setGraph(d1);
      }
    } catch (e) {
      console.log(`error---${e}`);
      dispatch(updatingLoading(false));
    }
  };

  const updateChartFunction = async () => {
    try {
      console.log("first-------");
      dispatch(updatingLoading(true));
      let body = {
        project_id: project_id,
        chart_title: data?.chart_title,
        chart_question: question ? question : data?.chart_question,
        sql_query: sql,
        chart: JSON.stringify(graph),
      };
      console.log("body---->", body);
      const updateChart = await updateChartAPI(data?.id, body);
      dispatch(updatingLoading(false));
      if (updateChart.status) {
        navigate(
          `/appstore/datavizard/projects/projectsoverview/${projectName}`,
          {
            state: {
              project_id: project_id,
            },
          }
        );
      }
    } catch { }
  };

  function backBtn() {
    navigate(`/appstore/datavizard/projects/projectsoverview/${projectName}`, {
      state: {
        project_id: project_id,
        datasource_id: datasource_id
      },
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    generateSql();
    setQuestion(ques);
    setQues("");
  };

  return (
    <div className={styles.ifdataexixts}>
      <div className="w15">
        <ul className="datavizardSidebar">
          <Link to="/appstore/datavizard/projects">
            <li className="active">
              <img src={projectImg} alt="" /> Projects
            </li>
          </Link>
          <Link to="/appstore/datavizard/datasource">
            <li>
              <img src={datasourceImg} alt="" /> Datasources
            </li>
          </Link>
        </ul>
      </div>
      <div className="w85 pad32">
        <div className={styles.headingg}>
          <h5>{projectName}</h5>
        </div>
        <div className="justifyBetween alignCenter borderBottom marBot8px">
          <h2 className={styles.headline}>Overview</h2>
          <div className="gap8px">
            {/* <Link to={`/appstore/datavizard/projects/projectsoverview/${projectName} `}> */}
            <button onClick={backBtn} className="cancelBtn">
              Close
            </button>
            {/* </Link> */}
            <button onClick={updateChartFunction} className="activeBtn">
              Save
            </button>
          </div>
        </div>

        <div className="flex height70dvh">
          <div className="w60">
            <div className="justifyBetween alignCenter">
              <div>
                <h3 className="f20">{data?.chart_title} </h3>
                <h6 className="f16">{question ? question : data?.chart_question}</h6>
              </div>
              <div className="gap8px padBothImg ">
                <img src={graphIcon} onClick={() => setTableOrGraph("Graph")} alt="" />
                <img src={tableIcon} onClick={() => setTableOrGraph("Table")} alt="" />
              </div>
            </div>
            <div className="overFLowScroll">
              {
                tableOrGraph === "Graph" ? <>
                  {graph && (
                    <PlotGraph data={graph?.data} layout={graph?.layout} />
                  )}
                </>
                  : <>
                    {Object.keys(tableData).length > 0
                      &&
                      <table>
                        <thead>
                          <tr>
                            {
                              Object.keys(tableData).map((ele, idx) => {
                                return (
                                  <th key={idx}>{ele} </th>
                                )
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(tableData[Object.keys(tableData)[0]])?.map(
                            (ele, idx) => {
                              let c1 = Object.keys(tableData);
                              return (
                                <tr>
                                  {c1?.map((item, idx1) => {
                                    let s1 = tableData[item][idx];
                                    if (!s1) {
                                      s1 = "-"
                                    }
                                    return (
                                      <td key={idx1}> {s1} </td>
                                    );
                                  })}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    }
                  </>}
            </div>
          </div>
          <div className="w40">
            <div className="headerPart flex justifyBetween">
              <button className="activeBtn">SQL</button>
              <button onClick={() => executeSql(sql, "nograph")} className="cancelBtn">Re-run Query</button>
            </div>
            <textarea
              value={sql}
              className="pad12px textareaDark height50dvh"
              onChange={(e) => setSql(e.target.value)}
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flexCenter">
          <div className="w40vw posRel">
            <input
              type="text"
              className="blackInput"
              value={ques}
              onChange={(e) => setQues(e.target.value)}
              placeholder="Enter a question!"
            />
            <img
              src={playBtn}
              alt=""
              className="posSendBtn"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChartAndTables;
