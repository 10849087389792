import React, { createContext, useEffect, useRef, useState } from 'react'
import styles from "./datadivesidebar.module.css";
import cx from "classnames"
import { Toast } from 'primereact/toast';
import Charging from '../../../Charging';
import { deleteSessionImagechatAPI, getSessionImageChatAPI } from '../../../commonApi';
import CreateRagModel from './CreateRagModel';
import EachRag from './EachRag';

export const IndexData = createContext();

const DataDiveSidebar = ({ updateSession, currentSession }) => {
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [ragModel, setRagModel] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [initialFullData, setInitialFullData] = useState([]);
  const [optionsIndex, setOptionsIndex] = useState(-1);

  const indexValue = { currentSession: currentSession, optionsIndex: optionsIndex, updateOptionsIndex: setOptionsIndex }

  useEffect(() => {
    getSession();
  }, [])

  useEffect(() => {

  }, [currentSession])

  async function getSession(data) {
    try {
      setOpen(true);
      const getSessionDataDive = await getSessionImageChatAPI();
      setOpen(false);
      if (getSessionDataDive?.success) {
        setFullData(getSessionDataDive.data);
        setInitialFullData(getSessionDataDive.data);
        if (!data || data === currentSession) {
          // setCurrentSession(getSessionDataDive.data[0].datadive_session);
          updateSession(getSessionDataDive.data[0].datadive_session);
        }
      }
    }
    catch (e) {
      console.log("err---", e);
      setOpen(false);
    }
  }

  async function deleteImagechat(e, sessionId) {
    try {
        e.stopPropagation();
        setOpen(true);
         console.log('sessionId---', sessionId);
        let body = {
            "title": sessionId
        
        }
        console.log('body----->', body);
        const deleteSession = await deleteSessionImagechatAPI(body);
        setOpen(false);
         console.log('deleteSession--->', deleteSession);
        if (deleteSession.success) {
          getSession()
        } else {
            console.log('error');
        }

    } catch (e) {

    }
}
  function searchChat(e) {
    let { value } = e.target;
    let ans = [];
    for (let i of initialFullData) {
      if (i.imagechat_session.toLowerCase().includes(value.toLowerCase())) {
        ans.push(i);
      }
    }
    if (!value) {
      setFullData(initialFullData)
    } else {
      setFullData(ans)
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.full}>
        <div className={styles.search}>
          <div onClick={() => setRagModel(true)} className={cx("commonbtn")}>
            <i className="fa fa-plus"></i>
            <span>New Chat</span>
          </div>
          <div className={cx("posrel")}>
            <input
              type="text"
              onChange={searchChat}
              autoComplete="off"
              placeholder="Serach.."
              className={styles.input}
            />
            <i
              className={cx(
                "fa fa-solid fa-magnifying-glass",
                styles.searchabs
              )}
            ></i>
          </div>
        </div>
        <IndexData.Provider value={indexValue}>
          {
            fullData?.map((ele, idx) => {
              return (
                <EachRag
                  key={idx}
                  index={idx}
                  optionsShow={idx === optionsIndex}
                  getSession={getSession}
                  deleteOne={deleteImagechat}
                  // changeSession={(data) => setCurrentSession(data)}
                  changeSession={(data) => updateSession(data)}
                  data={ele}
                />
              )
            })
          }
        </IndexData.Provider>
      </div>

      {
        ragModel && <CreateRagModel getData={getSession} closeModel={() => setRagModel(false)} />
      }

    </>

  )
}

export default DataDiveSidebar;