import React, { useState, useRef } from "react";
import styles from "./Popforlink.module.css";
import Charging from "../../../Charging";

import close from "../../../assests/images/model/close.png";
import success from "../../../assests/images/folder/success.png";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

const Popforlink = ({ closeModel, onExtract }) => {
  const [open, setOpen] = useState(false);
  const [modelName, setModelName] = useState("");
  const { ragName } = useParams();
  const navigate = useNavigate();
  const [uploadStatus, setUploadStatus] = useState("");

  const toast = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const filename = modelName.split("/").pop();
    setUploadStatus(`File Extracted: ${filename}`);
    onExtract(filename, modelName); // Pass both filename and link
  };

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault(); // Prevent the form from causing a page reload
  //   const filename = modelName.split("/").pop(); // Extract the filename from the URL
  //   setUploadStatus(`File Extracted: ${filename}`);
  // };

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />

      <div className={styles.modal}>
        <div className={styles.modelBox}>
          <div className="justifyEnd">
            <img className="cursor" onClick={closeModel} src={close} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label className={styles.activeLabel} htmlFor="">
                Link
              </label>
            </div>
            <label className={styles.Label} htmlFor="">
              Provide the link below
            </label>
            <input
              className={styles.input}
              type="text"
              required
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />

            {uploadStatus && (
              <div className={styles.uploadStatusContainer}>
                <img
                  src={success}
                  alt="success"
                  className={styles.successIcon}
                />
                <p className={styles.uploadStatusText}>{uploadStatus}</p>
              </div>
            )}

            <div className={styles.modelButton}>
              <button onSubmit={handleSubmit} className={styles.access}>
                Submit
              </button>
              <button onClick={closeModel} className={styles.cancel}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Popforlink;
