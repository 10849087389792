import React from "react";
import styles from "../projects/project.module.css";
import reload from "../../../assests/images/datavizard/reload.png"

const ProjectChart = () => {
  return (
    <div>
      <div className={styles.heading}>
        <h5>Project Name</h5>
      </div>
      {/* <div className={styles.w15}>
        <ul className={styles.datavizardSidebar}>
          <li className="active">
            <img src={projectImg} alt="" /> Projects{" "}
          </li>
          <li>
            <img src={datasourceImg} alt="" /> Datasources{" "}
          </li>
        </ul>
      </div> */}

      <div className={styles.over}>
        <div className={styles.overview}>
          <h2 className={styles.headline}>Overview</h2>
          <div className={styles.btn}>
            <button className={styles.activeBtn}>Close</button>
            <button className={styles.activeBtn}>Save</button>
          </div>
        </div>
      </div>
      <div className={styles.wid100}>
        <div className={styles.left}>
          <div className={styles.line}>
          <h5>Top10 Customers by most Spent</h5>
          <h6>Who are our best costomers ?</h6>
          </div>
        </div>
        <div className={styles.right}>
            <div className={styles.sql}>
                <h5>SQL</h5><img src={reload}/>
            </div>
             </div>
      </div>
    </div>
  );
};

export default ProjectChart;
