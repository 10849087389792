import React, { useEffect, useState } from 'react';
import styles from './commonsidebar.module.css'
import EachSections from './EachSections';

const EachPeriod = ({ eachMonth, currentSession, clickedSession, category, allData }) => {

    const [month, setMonth] = useState("");
    const [eachSess, setEachsess] = useState([]);

    useEffect(() => {
        let v1 = Object.keys(eachMonth);
        let v2 = Object.values(eachMonth);
        setMonth(v1[0]);
        setEachsess(v2[0]);
    }, [eachMonth])

    function presentSession(data) {
        clickedSession(data);
    }

    function refresh() {
        allData()
    }

    return (
        <div className={styles.eachPeriod}>
            <h4 className={styles.month}>{month}</h4>
            <EachSections eachSession={eachSess} currentSession={currentSession} presentSession={presentSession} category={category} refresh={refresh} />
        </div>
    )
}

export default EachPeriod