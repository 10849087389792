import CommonSidebar from "../../../components/commonSidebar/CommonSidebar";
import styles from "../Routeoptimizer/Routeoptimizer.module.css";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import fileup from "../../../assests/images/appstore/fileupload.svg";
import {
  PostRouteoptimizers,
  Routeoptimizers,
  postrouteoptiguideapi,
} from "../../../commonApi";
import roboIcon from "../../../assests/images/normalchat/roboIcon.png";
import Charging from "../../../Charging";

const Routeoptimizer = () => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const [uploadStatus, setUploadStatus] = useState("");

  const handleImageClick = () => {
    document.getElementById("excel-file-input").click();
  };

  const postQuestions = async (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      const queryData = {
        human_message: query, // Assuming the query is stored in the 'query' state
      };
      const res = await PostRouteoptimizers(queryData); // Call the function with query data
      setOpen(false);
      console.log("Response from posting questions:", res);
      console.log("---------------", queryData);

      await fetchData(); // Wait for fetchData to complete before proceeding
      // Handle response or set feedback as needed
      setQuery("");
    } catch (error) {
      console.error("Error posting questions:", error);
      setOpen(false);
      // Set upload status or handle error as needed
    }
  };

  const uploadexlData = async (e) => {
    const file = e.target.files[0];
    console.log("file--->", file);
    if (file) {
      setOpen(true);
      try {
        const formData = new FormData();
        formData.append("files", file);

        const postForm = await postrouteoptiguideapi(formData);
        setOpen(false);
        console.log("postForm-------", postForm);
        // Set upload status
        setUploadStatus("File uploaded successfully!");
        setTimeout(() => {
          setUploadStatus(""); // Clear upload status after 5 seconds
        }, 2000);
        fetchData();
      } catch (error) {
        console.error("Error uploading file:", error);
        setOpen(false);
        // Set upload status
        setUploadStatus("Error uploading file. Please try again.");
      }
    }
  };

  async function fetchData() {
    try {
      setOpen(true);
      const getroute = await Routeoptimizers();
      setOpen(false);
      console.log("getroute--", getroute);
      if (getroute.success) {
        setRoute(getroute.data);
      }
    } catch (e) {
      console.log("error--", e);
      setOpen(false);
    }
  }

  return (
    <>
      <Charging open={open} />
      <div className={styles.main}>
        <div className={styles.route}>
          {route?.map((item, idx) => {
            // {Array(10).fill("").map((item, idx) => {
            return (
              <div>
                <div key={idx} className={cx(styles.human)}>
                  <div className="flex w70 gap6px">
                    <div className="w8"></div>
                    <div className={styles.content}>
                      <div className={"justifyEnd"}>
                        {/* Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Deleniti eveniet voluptates ipsam, porro enim
                        impedit ullam, suscipit esse odio ut quidem in quos,
                        incidunt praesentium vero eos adipisci molestiae sequi? */}
                        {/* {item?.genai_user[0]} */}
                        {item?.human_message}
                      </div>
                      <div className={styles.time}>
                        {/* mani */}
                        {/* {item?.genai_user[1]} */}
                      </div>
                    </div>
                    <div className={`w8 ${styles.circle}`}>
                      <p> MP </p>
                    </div>
                  </div>
                </div>

                <div className={cx(styles.robot)}>
                  <div className={styles.flex_w70}>
                    <div className="w8">
                      <img src={roboIcon} alt="" />
                    </div>
                    <div className={styles.content}>
                      <div className={"justifyStart colDir"}>
                        {/* Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Tempore atque dolore hic est voluptatem maiores
                        rem saepe, voluptate, nisi ipsa doloribus exercitationem
                        deleniti ut. Deleniti impedit expedita aut aspernatur
                        unde. */}
                        {item?.ai_message?.split("\n").map((item, idx123) => {
                          return (
                            <p key={idx123} className={styles.spaceChat}>
                              {item}
                            </p>
                          );
                        })}
                      </div>
                      <div className={styles.time}>
                        mass
                        {/* {item?.genai_bot[1]} */}
                      </div>
                    </div>
                    <div className="w8"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className={cx(styles.serching, "flexColCenter")}>
          <form onSubmit={postQuestions} className={styles.formm}>
            <div className={styles.inpEnter}>
              <input
                type="text"
                name="searching"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                autoComplete="off"
                placeholder="Enter your query..."
              />

              <button
                onClick={postQuestions}
                disabled={query.length > 0 ? false : true}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 36 36"
                  fill={query.length > 0 ? "#2D9BD5" : "#B1B1B1"}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" fill="#F5F5F5" />
                  <g clipPath="url(#clip0_0_1)">
                    <rect
                      width="1920"
                      height="1000"
                      transform="translate(-1318 -920)"
                      fill="#070D19"
                    />
                    <rect
                      x="-669"
                      width="721"
                      height="38"
                      rx="6"
                      fill="#161D2F"
                    />
                    <path d="M8.60547 7.88672V15.5651L19.1154 17.8234L8.60547 20.0817V27.7601L26.8227 17.8234L8.60547 7.88672Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_1">
                      <rect
                        width="1920"
                        height="1000"
                        fill="white"
                        transform="translate(-1318 -920)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className={styles.fileupld}>
              <img
                src={fileup}
                onClick={handleImageClick} // Trigger file input click on image click
                style={{ cursor: "pointer" }} // Optional: Changes cursor on hover
              />
              <input
                type="file"
                id="excel-file-input"
                accept=".xlsx"
                onChange={uploadexlData}
                style={{ display: "none" }} // Keep input hidden
              />
            </div>

            {uploadStatus && (
              <div className={styles.uploadStatus}>{uploadStatus}</div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Routeoptimizer;
